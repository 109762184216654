import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import Layout from './components/Layout';
import { LoginForm } from './pages/login';
import { CreatePassword } from './pages/Password';
import { useAuth } from './components/AuthProvider';
import NotFound from './components/NotFound';
import MaintenanceTab from './pages/maintenance/MaintenanceTab';
import SiteEventsTab from './pages/siteEvents/SiteEventsTab';
import ComplianceTabs from './pages/compliance/ComplianceTabs';
import SiteContainer from './pages/settings/SiteSettings/SiteContainer';
import SiteSelectProvider from './components/SiteProvider';

const Overview = React.lazy(() => import('./pages/overview/Overview'));
const EquipmentList = React.lazy(() => import('./pages/machines/list/EquipmentList'));
const EquipmentDetail = React.lazy(() => import('./pages/machines/detail/EquipmentDetail'));
const ReportList = React.lazy(() => import('./pages/reporting/list/ReportList'));
const ReportNew = React.lazy(() => import('./pages/reporting/ReportNew'));
const Smu = React.lazy(() => import('./pages/smu/Smu'));
const SmuConfiguration = React.lazy(() => import('./pages/smu/SmuConfiguration'));
const Settings = React.lazy(() => import('./pages/settings/Settings'));
const UserSettings = React.lazy(() => import('./pages/settings/user/UserSettings'));
const OrgSettings = React.lazy(() => import('./pages/settings/OrgSettings/OrgSettings'));
const SiteSettings = React.lazy(() => import('./pages/settings/SiteSettings/SiteSettings'));

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { user } = useAuth();
  if (!user) return <Navigate to="/login" replace />;
  return <>{children}</>;
};

const Suspense = ({ children }: { children: React.ReactNode }) => (
  <React.Suspense fallback={<div>Loading...</div>}>{children}</React.Suspense>
);

export const routes: RouteObject[] = [
  {
    path: '/login',
    element: <LoginForm />,
  },
  {
    path: '/signup',
    element: <CreatePassword />,
  },
  {
    element: (
      <ProtectedRoute>
        <SiteSelectProvider>
          <Layout />
        </SiteSelectProvider >
      </ProtectedRoute>
    ),
    children: [
      {
        path: '/',
        element: <Suspense><Overview /></Suspense>,
      },
      {
        path: 'events',
        element: <Suspense><SiteEventsTab /></Suspense>,
        children: [
          { index: true, element: null },
          { path: 'insights', element: null },
        ],
      },
      {
        path: 'maintenance',
        element: <Suspense><MaintenanceTab /></Suspense>,
        children: [
          { index: true, element: null },
          { path: 'insights', element: null },
        ],
      },
      {
        path: 'equipment',
        children: [
          { index: true, element: <Suspense><EquipmentList /></Suspense> },
          { path: ':id/:tab?', element: <Suspense><EquipmentDetail /></Suspense> },
        ],
      },
      {
        path: 'reports',
        children: [
          { index: true, element: <Suspense><ReportList /></Suspense> },
          { path: ':id?', element: <Suspense><ReportNew /></Suspense> },
        ],
      },
      {
        path: 'smu',
        children: [
          { index: true, element: <Suspense><Smu /></Suspense> },
          { path: 'configuration', element: <Suspense><SmuConfiguration /></Suspense> },
        ],
      },
      {
        path: 'compliance',
        element: <Suspense><ComplianceTabs /></Suspense>,
        children: [
          { index: true, element: null },
          { path: 'configuration', element: null },
        ],
      },
      {
        path: 'settings',
        element: <Suspense><Settings /></Suspense>,
        children: [
          { index: true, element: <Suspense><UserSettings /></Suspense> },
          {
            path: 'sites/:id',
            element: <Suspense><SiteContainer /></Suspense>,
            children: [
              { index: true, element: null },
              { path: 'maintenance', element: null },
              { path: 'events', element: null },
              { path: 'smu', element: null },
            ],
          },
          { path: 'sites', element: <Suspense><SiteSettings /></Suspense> },
          { path: 'organization', element: <Suspense><OrgSettings /></Suspense> },
        ],
      },
      {
        path: '*',
        element: <Navigate to="/404" replace />,
      },
    ],
  },
  {
    path: '/404',
    element: <NotFound />,
  },
];