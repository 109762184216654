import { useState, useEffect, useCallback } from 'react';
import { ComplianceItem } from '../../types';
import { fetchComplianceItems, insertComplianceItem, deleteComplianceItem, ComplianceItemPayload } from '../../queries';
import { useConfirmationDialog } from "@/components/confirmDialog/ConfirmDialogProvider";

export const useComplianceItems = (
  siteId: string | undefined,
  selectedGroup: ComplianceItem | undefined,
  setSelectedGroup: (group: ComplianceItem | undefined) => void
) => {
  const [complianceItems, setComplianceItems] = useState<ComplianceItem[]>([]);
  const { getConfirmation } = useConfirmationDialog();

  useEffect(() => {
    if (!siteId) return;

    const loadComplianceItems = async () => {
      const { data } = await fetchComplianceItems(siteId);
      if (data) {
        setComplianceItems(data);
        if (!selectedGroup) {
          setSelectedGroup(data[0]);
        }
      }
    };

    loadComplianceItems();
  }, [siteId, selectedGroup, setSelectedGroup]);

  const addOrUpdateItem = useCallback((newItem: ComplianceItem) => {
    setComplianceItems(prevItems => {
      const index = prevItems.findIndex(item => item.id === newItem.id);
      if (index !== -1) {
        // Update existing item
        const updatedItems = [...prevItems];
        updatedItems[index] = newItem;
        return updatedItems;
      } else {
        // Add new item
        return [...prevItems, newItem];
      }
    });
    setSelectedGroup(newItem);
  }, [setSelectedGroup]);

  const handleAddOrUpdateItem = async (payload: ComplianceItemPayload) => {
    const { data, error } = await insertComplianceItem(siteId, payload);
    if (error) {
      alert(error.message);
      return false;
    } else {
      addOrUpdateItem(data);
      return true;
    }
  };

  const handleDeleteItem = async (item: ComplianceItem) => {
    const confirmed = await getConfirmation({
      title: "Delete Compliance Item",
      message: "Are you sure you want to delete this item? This action cannot be undone.",
    });

    if (confirmed) {
      const { error } = await deleteComplianceItem(item.id);
      if (error) {
        alert(error.message);
        return false;
      } else {
        setComplianceItems(prevItems => prevItems.filter(i => i.id !== item.id));
        if (selectedGroup?.id === item.id) {
          setSelectedGroup(complianceItems[0]);
        }
        return true;
      }
    }
    return false;
  };

  return {
    complianceItems,
    handleAddOrUpdateItem,
    handleDeleteItem
  };
};