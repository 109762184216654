import { Roles, UserType } from '@/types/user';
import { supabase } from '@/lib/supabaseClient';
import { AuthError, Session, User, WeakPassword } from '@supabase/supabase-js';
import { createContext, useState, useEffect, useContext } from 'react';
import { LoadingSpinner } from './ui/loadingSpinner';

type AuthContextType = {
  user: User | null
  appUser: UserType | null,
  signIn?: (username: string, password: string) => Promise<{
    data: {
      user: User;
      session: Session;
      weakPassword?: WeakPassword | undefined;
    } | {
      user: null;
      session: null;
      weakPassword?: null | undefined;
    };
    error: AuthError | null;
  }>
  signOut?: () => Promise<{
    error: AuthError | null;
  }>,
  getAppUser?: (userId: string) => void
}

export interface ProviderProps {
  children?: React.ReactNode;
}

const AuthContext = createContext<AuthContextType>({
  user: null,
  appUser: null,
});

export const useAuth = () => {
  return useContext(AuthContext);
};

export const fetchAllSites = async (orgId: string) => {
  let { data, error } = await supabase
    .from('sites')
    .select("*")
    .eq('organization_id', orgId)
    .order('created_at', { ascending: true })

  return { data, error };
}

export const fetchUserSites = async (userId: string | undefined) => {
  if (!userId) throw new Error('User ID is required');

  let { data, error } = await supabase
    .from('user_sites')
    .select("*, sites(*), roles(*)")
    .eq('user_id', userId)
    .order('created_at', { ascending: true })

  return { data, error };
}

const fetchUser = async (userId: string | undefined) => {
  if (!userId) throw new Error('User ID is required');

  let { data, error } = await supabase
    .from('users')
    .select("*, organization:organizations(*)")
    .eq('id', userId)
    .single()

  return { data, error };
}

const AuthProvider = ({ children }: ProviderProps) => {
  const [user, setUser] = useState<User | null>(null);
  const [appUser, setAppUser] = useState<UserType | null>(null);
  const [session, setSession] = useState<Session | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const getAppUser = async (userId: string) => {
    try {
      const { data: user, error: userError } = await fetchUser(userId);
      if (userError) throw userError;

      let userSites;
      let role;
      if (user.is_org_owner) {
        const { data: sites, error: sitesError } = await fetchAllSites(user.organization.id);
        if (sitesError) throw sitesError;
        userSites = sites;
        role = Roles.Owner;
      } else {
        const { data: userSitesData, error: sitesError } = await fetchUserSites(userId);
        if (sitesError) throw sitesError;
        userSites = userSitesData?.map(entry => entry.sites);
        role = userSitesData?.[0]?.roles?.role as string;
      }

      const appuser = { ...user, sites: userSites, role };
      setAppUser(appuser);
    } catch (error) {
      console.error('Error fetching user data:', error);
      alert(error instanceof Error ? error.message : 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  const handleSessionChange = (session: Session | null) => {
    setSession(session);
    setUser(session?.user ?? null);
    if (session?.user) {
      getAppUser(session.user.id);
    } else {
      setAppUser(null);
      setLoading(false);
    }
  };

  useEffect(() => {
    const { data: listener } = supabase.auth.onAuthStateChange((_event, session) => {
      handleSessionChange(session);
    });

    return () => {
      listener?.subscription.unsubscribe();
    };
  }, []);

  const signIn = async (username: string, password: string) => {
    const { data, error } = await supabase.auth.signInWithPassword({
      email: username,
      password: password
    })
    console.log('data: ', data);
    console.log('error: ', error);
    return { data, error };
  };

  const signOut = async () => {
    const { error } = await supabase.auth.signOut();
    console.log('error: ', error);
    if (!error) {
      setUser(null);
      setSession(null);
      setAppUser(null);
    }
    return { error };
  };

  return (
    <AuthContext.Provider value={{ user, appUser, signIn, signOut, getAppUser }}>
      {!loading ? children : <div className="w-screen h-screen flex items-center justify-center"><LoadingSpinner /></div>}
    </AuthContext.Provider>
  );
};

export default AuthProvider;