import { supabase } from "@/lib/supabaseClient";
import { PaginationState } from "@tanstack/react-table";
import moment from "moment";
import { DateRange } from "react-day-picker";

const fetchMaintenanceLogs = async (siteId: string | undefined, pagination?: PaginationState, statusFilters?: string[], systemFilters?: string[], typeFilters?: string[], equipmentFilters?: string[], dateRange?: DateRange) => {
  if (!siteId) throw Error("siteId requied to fetch logs");

  let query = supabase
    .from('maintenance_logs')
    .select("*, equipment(*), status:maintenance_statuses(*), system:maintenance_systems(*), type:maintenance_types(*)", { count: 'exact' })
    .eq('site_id', siteId)
    .order('date_down', { ascending: false })
    .order('time_down', { ascending: false })

  if (pagination) {
    const startRange = pagination.pageIndex * (pagination.pageSize - 1);
    const endRange = startRange + (pagination.pageSize - 1);
    query = query.range(startRange, endRange);
  }

  if (statusFilters && statusFilters.length > 0) {
    query = query.filter('status_id', 'in', `(${statusFilters.join(',')})`)
  }

  if (systemFilters && systemFilters.length > 0) {
    query = query.filter('system_id', 'in', `(${systemFilters.join(',')})`)
  }

  if (typeFilters && typeFilters.length > 0) {
    query = query.filter('downtime_type_id', 'in', `(${typeFilters.join(',')})`)
  }

  if (equipmentFilters && equipmentFilters.length > 0) {
    query = query.filter('equipment_id', 'in', `(${equipmentFilters.join(',')})`)
  }

  if (dateRange && dateRange.from && dateRange.to) {
    const start = moment(dateRange.from).format('YYYY-MM-DD');
    const end = moment(dateRange.to).format('YYYY-MM-DD');
    query = query.lte('date_down', end).gte('date_down', start);
  }

  const { data, error, count } = await query;
  return { data, error, count };
}

const fetchMaintenanceStatuses = async (siteId: string | undefined) => {
  if (!siteId) throw Error("siteId requied to fetch statuses");

  let { data, error } = await supabase
    .from('maintenance_statuses')
    .select("*")
    .eq('site_id', siteId)

  return { data, error }
}

const fetchMaintenanceSystems = async (siteId: string | undefined) => {
  if (!siteId) throw Error("siteId requied to fetch systems");

  let { data, error } = await supabase
    .from('maintenance_systems')
    .select("*")
    .eq('site_id', siteId)

  return { data, error }
}

const fetchMaintenanceTypes = async (siteId: string | undefined) => {
  if (!siteId) throw Error("siteId requied to fetch types");

  let { data, error } = await supabase
    .from('maintenance_types')
    .select("*")
    .eq('site_id', siteId)

  return { data, error }
}

export {
  fetchMaintenanceLogs,
  fetchMaintenanceStatuses,
  fetchMaintenanceSystems,
  fetchMaintenanceTypes
}
