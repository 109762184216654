import HeaderCol from './HeaderCol';
import EquipmentCol from './EquipmentCol';
import { Card, CardContent } from "@/components/ui/card";
import { useEffect, useState } from 'react';
import { useSiteSelector } from '@/components/SiteProvider';
import { LoadingSpinner } from '@/components/ui/loadingSpinner';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import ComplianceItemEntryForm, { formSchema } from './form/ComplianceItemEntryForm';
import { fetchComplianceItems, fetchEquipmentCompliance, updateComplianceEntry } from './queries';
import { z } from 'zod';
import { Compliance, ComplianceEntryForm, ComplianceItem, Equipment } from './types';

const TestGroup = () => {
  const { selectedSite } = useSiteSelector();
  const [complianceComponents, setComplianceComponents] = useState<ComplianceItem[]>();
  const [equipmentCompliance, setEquipmentCompliance] = useState<Equipment[]>();
  const [formOpen, setFormOpen] = useState<boolean>(false);
  const [selectedEntry, setSelectedEntry] = useState<ComplianceEntryForm>();

  const getComplianceItems = async () => {
    if (!selectedSite) return;

    const { data: equipmentAndCompliance } = await fetchEquipmentCompliance(selectedSite.id)
    const { data: compliance } = await fetchComplianceItems(selectedSite.id)
    setComplianceComponents(compliance as ComplianceItem[]);

    const equipmentWithCompliance = equipmentAndCompliance?.filter(equipment => equipment.compliance_item_equipment_entry.length > 0)
    setEquipmentCompliance(equipmentWithCompliance as Equipment[]);
  }

  useEffect(() => {
    getComplianceItems();
  }, [selectedSite]);

  const onCellClick = (record: Compliance, component: ComplianceItem, equipment: Equipment) => {
    setSelectedEntry({
      id: record.id,
      component: component.name,
      equipment: equipment.site_id,
      group: record.compliance_item_group.name,
      intervalType: record.compliance_item_group.interval_type,
      lastDone: record.last_done
    });
    setFormOpen(true);
  }

  const onCloseForm = () => {
    setFormOpen(false);
    setSelectedEntry(undefined);
  }

  const onFormSuccess = async (values: z.infer<typeof formSchema>) => {
    console.log(values);
    const { data, error } = await updateComplianceEntry(values.id, { last_done: values.lastDone });
    if (error) {
      alert(error.message);
    }
    if (data) {
      console.log(data);
      setFormOpen(false);
      setSelectedEntry(undefined);
      await getComplianceItems();
    }
  }

  return (
    <>
      <Card className="flex-1">
        <CardContent className="pt-6">
          {complianceComponents && equipmentCompliance && (
            <div className="flex w-full overflow-hidden overflow-x-auto border-r relative">
              <div className="sticky top-0 left-0 bg-card shadow-lg">
                <HeaderCol header components={complianceComponents} />
              </div>
              <div className="flex">
                {equipmentCompliance.map((equipment) => {
                  return <EquipmentCol components={complianceComponents} equipment={equipment} onCellClick={onCellClick} />
                })}
              </div>
            </div>
          )}

          {(!complianceComponents || !equipmentCompliance) && (
            <div className="flex gap-2 items-center justify-center rounded-md border py-80">
              <LoadingSpinner />
              <p className="font-semibold">Fetching Compliance...</p>
            </div>
          )}
        </CardContent>
      </Card>

      <Dialog open={formOpen} onOpenChange={setFormOpen}>
        <DialogContent className="max-w-xl">
          <DialogHeader>
            <DialogTitle>New Compliance Component</DialogTitle>
          </DialogHeader>
          {selectedEntry && <ComplianceItemEntryForm onClose={onCloseForm} onFormSuccess={onFormSuccess} selectedEntry={selectedEntry} />}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default TestGroup;