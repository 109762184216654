import { supabase } from "@/lib/supabaseClient";
import { PaginationState } from "@tanstack/react-table";
import moment from "moment";
import { DateRange } from "react-day-picker";


const fetchSiteEvents = async (siteId: string | undefined, pagination?: PaginationState, eventTypeFilters?: string[], eventStatusFilters?: string[], dateRange?: DateRange) => {
  if (!siteId) throw Error("siteId requied to fetch sites");

  let query = supabase
    .from('site_events')
    .select("*, ...site_event_statuses(status), ...site_event_types(type)", { count: 'exact' })
    .eq('site_id', siteId)
    .order('date_down', { ascending: false })
    .order('time_down', { ascending: false })

  if (pagination) {
    const startRange = pagination.pageIndex * (pagination.pageSize - 1);
    const endRange = startRange + (pagination.pageSize - 1);
    query = query.range(startRange, endRange);
  }

  if (eventTypeFilters && eventTypeFilters.length > 0) {
    query = query.filter('event_type', 'in', `(${eventTypeFilters.join(',')})`)
  }

  if (eventStatusFilters && eventStatusFilters.length > 0) {
    query = query.filter('work_status', 'in', `(${eventStatusFilters.join(',')})`)
  }

  if (dateRange && dateRange.from && dateRange.to) {
    const start = moment(dateRange.from).format('YYYY-MM-DD');
    const end = moment(dateRange.to).format('YYYY-MM-DD');
    query = query.lte('date_down', end).gte('date_down', start);
  }

  const { data, error, count } = await query;
  return { data, error, count };
}

const fetchSiteTypes = async (siteId: string | undefined) => {
  if (!siteId) throw Error("siteId requied");

  let { data: siteStatuses, error: siteStatusesError } = await supabase
    .from('site_event_statuses')
    .select("*")
    .eq('site_id', siteId)

  let { data: siteTypes, error: siteTypeError } = await supabase
    .from('site_event_types')
    .select("*")
    .eq('site_id', siteId)


  return {
    siteTypes,
    siteTypeError,
    siteStatuses,
    siteStatusesError
  }
}

export {
  fetchSiteEvents,
  fetchSiteTypes
}