import { useState } from "react"
import {
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  getSortedRowModel,
  ColumnFiltersState,
  getFilteredRowModel,
  Row
} from "@tanstack/react-table"

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { Button } from "@/components/ui/button"
import Filters from "./Filters"
import { DataTablePagination } from "./DataTablePagination"
import { FilterConfig } from "../../pages/maintenance/table/columns"

interface DataTableProps<TData, TValue> {
  filterConfig?: FilterConfig[]
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
  addCTA?: {
    label: string
    method: () => void
  }
  onRowClick?: (args: any) => void
  hidePagination?: boolean
  actions?: React.ReactNode
}

export function DataTable<TData, TValue>({
  columns,
  data,
  addCTA,
  actions,
  filterConfig,
  hidePagination,
  onRowClick
}: DataTableProps<TData, TValue>) {
  const [sorting, setSorting] = useState<SortingState>([])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
    []
  )


  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnFilters
    },
    initialState: {
      pagination: {
        pageSize: 20,
      },
      columnVisibility: {
        'id': false,
        'machine_id': false,
        'time_down': false,
        'time_up': false,
        'interval_type': false,
      }
    }
  })

  //  style={{ width: table.getCenterTotalSize() }}
  return (
    <div>
      {(filterConfig || actions) &&
        <div className="pb-4 flex">
          {filterConfig && <Filters table={table} filterConfig={filterConfig} />}
          {actions && <div className="ml-auto flex gap-4">{actions}</div>}
        </div>
      }
      <div className="rounded-md border bg-card">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id} style={{ width: header.column.getSize() }} className="uppercase h-8 text-sm">
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                  onClick={() => {
                    console.log("on row click", row.original, onRowClick)
                    if (onRowClick) {
                      onRowClick(row.original);
                    }
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id} className="py-2 px-4" style={{ width: cell.column.getSize() }}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      {!hidePagination &&
        <div className="pt-4">
          <DataTablePagination table={table} />
        </div>
      }
    </div>
  )
}
