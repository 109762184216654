import { Button } from "@/components/ui/button"
import { DataTable } from "@/components/table/DataTable";
import { groupTableColumns } from "./groupTableColumns";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { GroupForm, formSchema } from "./GroupForm";
import { useState } from "react";
import { Machine } from "@/pages/machines/list/table/columns";
import { z } from "zod";
import { useSiteSelector } from "@/components/SiteProvider";
import { ComplianceItemGroup } from "../../types";
import { useComplianceGroups } from "./useComplianceGroups";

interface ComplianceGroupsProps {
  selectedItemId: string | undefined;
  equipment: Machine[];
}

const ComplianceGroups: React.FC<ComplianceGroupsProps> = ({ selectedItemId, equipment }) => {
  const { selectedSite } = useSiteSelector();
  const [selectedGroup, setSelectedGroup] = useState<ComplianceItemGroup | undefined>();
  const [open, setOpen] = useState<boolean>(false);
  const { groups, handleGroupSubmission, handleGroupDeletion } = useComplianceGroups(selectedSite?.id, selectedItemId);

  const onFormSuccess = async (values: z.infer<typeof formSchema>) => {
    if (!selectedSite) return;
    const success = await handleGroupSubmission(values, selectedSite, selectedGroup);
    if (success) {
      setOpen(false);
    }
  };

  const onRowEdit = (value: ComplianceItemGroup) => {
    setSelectedGroup(value);
    setOpen(true);
  };

  const onRowDelete = async (value: ComplianceItemGroup) => {
    await handleGroupDeletion(value.id);
  };

  const closeForm = () => {
    setSelectedGroup(undefined);
    setOpen(false);
  };

  return (
    <>
      <div className="border flex-1">
        <div className="flex w-full text-sm border-b py-1 px-4 font-medium">
          <p>Comliance Item Groups</p>
          <Button size="sm" className="ml-auto h-auto" onClick={() => setOpen(true)}>
            Add Group
          </Button>
        </div>
        <div className="p-4">
          {groups && groups.map((group) => (
            <div key={group.id} className="mb-4">
              <DataTable
                columns={groupTableColumns({ onEdit: onRowEdit, onDelete: onRowDelete })}
                data={[group]}
                hidePagination
              />
            </div>
          ))
          }
        </div>
      </div>

      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent className="max-w-xl">
          <DialogHeader>
            <DialogTitle>New Compliance Group</DialogTitle>
          </DialogHeader>
          {groups && (
            <GroupForm
              onClose={closeForm}
              onFormSuccess={onFormSuccess}
              complianceItemGroup={selectedGroup}
              complianceItemGroups={groups}
              equipment={equipment}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ComplianceGroups;
