import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { TimeSelectOptions, cn, closestTimeToNow } from "@/lib/utils"
import { EventStatuses, EventTypes } from "@/types/siteEventFields"
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import { Textarea } from "@/components/ui/textarea"
import { CalendarIcon } from "@radix-ui/react-icons"
import { Calendar } from "@/components/ui/calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import moment from "moment"
import { SiteEvent } from "../SiteEvents"
import { supabase } from "@/lib/supabaseClient"
import { useEffect, useState } from "react"
import { useAuth } from "@/components/AuthProvider"
import { useSiteSelector } from "@/components/SiteProvider"

interface SiteEventFormProps {
  onClose: () => void
  onFormSuccess: (values: z.infer<typeof formSchema>) => void
  siteEvent?: SiteEvent,
  siteStatuses: SiteStatusType[],
  siteTypes: SiteType[]
}

export const formSchema = z.object({
  id: z.string().optional(),
  site_id: z.string().optional(),
  description: z.string().optional(),
  event_type: z.string({
    required_error: 'A Type is required'
  }),
  work_status: z.string({
    required_error: 'A Status is required'
  }),
  date_down: z.date({
    required_error: 'A Date Down is required'
  }),
  time_down: z.string().optional(),
  date_up: z.date().optional(),
  time_up: z.string().optional()
});

type SiteStatusType = {
  id: string
  site_id: string
  status: string
}

type SiteType = {
  id: string
  site_id: string
  type: string
}

/*
  Sort out date types and formats for datepicker
*/
export function SiteEventForm({ onClose, onFormSuccess, siteEvent, siteStatuses, siteTypes }: SiteEventFormProps) {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      date_down: moment().toDate(),
      time_down: closestTimeToNow(TimeSelectOptions),
    },
  })

  useEffect(() => {
    if (!siteEvent || !form) return;
    form.reset({
      id: siteEvent.id,
      site_id: siteEvent.site_id,
      description: siteEvent.description || "",
      event_type: siteEvent.event_type,
      work_status: siteEvent.work_status,
      date_down: siteEvent.date_down ? moment(siteEvent.date_down, 'YYYY-MM-DD').toDate() : undefined,
      time_down: siteEvent.time_down ? moment(siteEvent.time_down, 'HH:mm').format('hh:mm A') : undefined,
      date_up: siteEvent.date_up ? moment(siteEvent.date_up, 'YYYY-MM-DD').toDate() : undefined,
      time_up: siteEvent.time_up ? moment(siteEvent.time_up, 'HH:mm').format('hh:mm A') : undefined
    })
  }, [siteEvent, form]);

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    console.log(values);
    onFormSuccess(values);
  }

  const handleClose = () => {
    form.reset();
    onClose();
  }

  return (
    <Form {...form}>
      <form className="space-y-2">
        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Description</FormLabel>
              <FormControl>
                <Textarea
                  placeholder="Describe the job"
                  className="resize-none"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="event_type"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Downtime Type</FormLabel>
              <Select onValueChange={field.onChange} value={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select a Type" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {siteTypes && siteTypes.map((siteType) => (<SelectItem key={siteType.id} value={siteType.id}>{siteType.type}</SelectItem>))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )
          }
        />
        <FormField
          control={form.control}
          name="work_status"
          render={({ field }) => (
            <FormItem className="flex-1">
              <FormLabel>Status</FormLabel>
              <Select onValueChange={field.onChange} value={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select a Status" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {siteStatuses && siteStatuses.map((siteStatus) => (<SelectItem key={siteStatus.id} value={siteStatus.id}>{siteStatus.status}</SelectItem>))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex gap-2 items-stretch">
          <FormField
            control={form.control}
            name="date_down"
            render={({ field }) => (
              <FormItem className="flex flex-col flex-1">
                <FormLabel>Date Down</FormLabel>
                <Popover>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <Button
                        variant={"outline"}
                        className={cn(
                          "w-[240px] pl-3 text-left font-normal",
                          !field.value && "text-muted-foreground"
                        )}
                      >
                        {field.value ? (
                          moment(field.value).format("DD/MM/YY")
                        ) : (
                          <span>Pick a date</span>
                        )}
                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      mode="single"
                      selected={field.value}
                      onSelect={field.onChange}
                      disabled={(date) =>
                        date > new Date() || date < new Date("1900-01-01")
                      }
                      initialFocus
                    />
                  </PopoverContent>
                </Popover>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="time_down"
            render={({ field }) => (
              <FormItem className="flex flex-col flex-1">
                <FormLabel>Time Down</FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select a Time" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {TimeSelectOptions.map((time) => (<SelectItem key={time} value={time}>{time}</SelectItem>))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex gap-2 items-stretch">
          <FormField
            control={form.control}
            name="date_up"
            render={({ field }) => (
              <FormItem className="flex flex-col flex-1">
                <FormLabel>Date Up</FormLabel>
                <Popover>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <Button
                        variant={"outline"}
                        className={cn(
                          "w-[240px] pl-3 text-left font-normal",
                          !field.value && "text-muted-foreground"
                        )}
                      >
                        {field.value ? (
                          moment(field.value).format("DD/MM/YY")
                        ) : (
                          <span>Pick a date</span>
                        )}
                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      mode="single"
                      selected={field.value}
                      onSelect={field.onChange}
                      disabled={(date) =>
                        date > new Date() || date < new Date("1900-01-01")
                      }
                      initialFocus
                    />
                  </PopoverContent>
                </Popover>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="time_up"
            render={({ field }) => (
              <FormItem className="flex flex-col flex-1">
                <FormLabel>Time Up</FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select a Time" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {TimeSelectOptions.map((time) => (<SelectItem key={time} value={time}>{time}</SelectItem>))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex">
          <Button type="button" onClick={handleClose} variant="ghost">Cancel</Button>
          <Button type="submit" onClick={form.handleSubmit(onSubmit)} className="ml-auto">Submit</Button>
        </div>
      </form >
    </Form >
  )
}