import { columns } from "./table/columns";
import { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import { Card, CardContent } from '@/components/ui/card';
import { useToast } from "@/components/ui/use-toast"
import { SiteEventForm, formSchema } from "./form/SiteEventForm";
import { z } from "zod";
import { Button } from "@/components/ui/button";
import { CalendarIcon } from "lucide-react";
import { supabase } from "@/lib/supabaseClient";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import { SiteEventsTable } from "./table/table";
import { SiteType } from "@/types/user";
import {
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  PaginationState
} from "@tanstack/react-table"
import { Filter } from "./table/Filter";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Calendar } from "@/components/ui/calendar";
import { DateRange } from "react-day-picker";
import moment from "moment";
import { cn } from "@/lib/utils";
import { useSiteSelector } from "@/components/SiteProvider";
import { useConfirmationDialog } from "@/components/confirmDialog/ConfirmDialogProvider";
import { fetchSiteEvents, fetchSiteTypes } from "./queries";

export type SiteEvent = {
  id?: string
  site_id: string
  description: string | null
  event_type: string
  type: string
  work_status: string
  status: string
  date_down: Date | string | null
  time_down: string | null
  date_up: Date | string | null
  time_up: string | null
}

type SiteEventStatuses = {
  id: string
  site_id: string
  status: string
}

type SiteEventTypes = {
  id: string
  site_id: string
  type: string
}

const SiteEvents = () => {
  const { selectedSite } = useSiteSelector();
  const [open, setOpen] = useState<boolean>(false);
  const [rowCount, setRowCount] = useState<number>(0);
  const [data, setData] = useState<SiteEvent[]>();
  const [siteTypes, setSiteTypes] = useState<SiteEventTypes[]>();
  const [siteStatuses, setSiteStatuses] = useState<SiteEventStatuses[]>();
  const [selectedEvent, setSelectedEvent] = useState<SiteEvent | undefined>(undefined);
  const [selectedEventFilters, setSelectedEventFilters] = useState<string[]>([]);
  const [selectedStatusFilters, setSelectedStatusFilters] = useState<string[]>([]);
  const [date, setDate] = useState<DateRange | undefined>()
  const { toast } = useToast()
  const { getConfirmation } = useConfirmationDialog();
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  })

  const onRowEdit = (siteEvent: SiteEvent) => {
    setSelectedEvent(siteEvent)
    openForm();
  }

  const onRowDelete = async (siteEvent: SiteEvent) => {
    const confirmed = await getConfirmation({
      title: "Delete site event",
      message: "Are you sure you want to delete this site event? This action cannot be undone.",
    });

    if (confirmed) {
      const { error } = await supabase
        .from('site_events')
        .delete()
        .eq('id', siteEvent.id)

      if (error) {
        alert(error.message);
      } else {
        toast({
          title: "Site event deleted",
        })

        if (!selectedSite) return;
        let { data, count, error } = await fetchSiteEvents(selectedSite.id, pagination, selectedEventFilters, selectedStatusFilters, date);
        if (error) {
          alert(error.message);
        }
        if (data) {
          setData(data);
        }
        if (count) {
          setRowCount(count);
        }
      }
    }
  }

  const table = useReactTable({
    data: data || [],
    columns: columns({ onEdit: onRowEdit, onDelete: onRowDelete }),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      pagination
    },
    rowCount: rowCount,
    manualPagination: true,
    onPaginationChange: setPagination,
    initialState: {
      pagination: {
        pageSize: pagination.pageSize,
      },
      columnVisibility: {
        'time_down': false,
        'time_up': false,
      }
    },
    meta: {
      onEditAction: (value: SiteType) => {
        console.log('edit clicked', value)
      },
      onDeleteAction: (value: SiteType) => {
        console.log('delete clicked', value)
      },
    },
  })

  useEffect(() => {
    if (!selectedSite) return;
    setData(undefined)
    setSiteTypes(undefined)
    setSiteStatuses(undefined)
    setSelectedEvent(undefined)
    setSelectedEventFilters([])
    setSelectedStatusFilters([])
    setDate(undefined)
  }, [selectedSite])

  useEffect(() => {
    if (!selectedSite) return;

    const getSiteValues = async () => {
      const {
        siteTypes,
        siteTypeError,
        siteStatuses,
        siteStatusesError
      } = await fetchSiteTypes(selectedSite.id);

      if (siteStatuses) {
        setSiteStatuses(siteStatuses);
      }

      if (siteTypes) {
        setSiteTypes(siteTypes);
      }
    }

    getSiteValues();
  }, [selectedSite]);

  useEffect(() => {
    if (!selectedSite || !siteStatuses || !siteTypes) return;

    const getSites = async () => {
      let { data, count, error } = await fetchSiteEvents(selectedSite.id, pagination, selectedEventFilters, selectedStatusFilters, date);
      if (data) {
        console.log(data);
        setData(data);
      }
      if (count) {
        setRowCount(count);
      }
    }

    getSites();
  }, [selectedSite, pagination, selectedEventFilters, selectedStatusFilters, date, siteStatuses, siteTypes])

  const onFormSuccess = async (values: z.infer<typeof formSchema>) => {
    const payload = {
      id: values.id,
      site_id: selectedSite?.id,
      description: values.description,
      event_type: values.event_type,
      work_status: values.work_status,
      date_down: values.date_down ? moment(values.date_down).format('YYYY-MM-DD') : null,
      time_down: values.time_down ? moment(values.time_down, 'hh:mm A').format('HH:mm') : null,
      date_up: values.date_up ? moment(values.date_up).format('YYYY-MM-DD') : null,
      time_up: values.time_up ? moment(values.time_up, 'hh:mm A').format('HH:mm') : null
    }
    const { data, error } = await supabase
      .from('site_events')
      .upsert(payload)
      .select()
      .single()

    if (error) {
      alert(error.message)
    }

    if (data) {
      let { data: siteData, count, error: siteError } = await fetchSiteEvents(selectedSite?.id, pagination, selectedEventFilters, selectedStatusFilters);
      if (siteData) {
        setData(siteData);
        setOpen(false);
        setSelectedEvent(undefined);
        toast({
          title: `Site Event was ${values.id ? "Updated" : "Created"}`,
        })
      }
      if (siteError) {
        alert(siteError.message);
      }
      if (count) {
        setRowCount(count);
      }
    }
  }

  const openForm = () => setOpen(true);

  const closeForm = () => {
    setOpen(false)
    setSelectedEvent(undefined);
  };

  const handleEventFilterChange = (value: string) => {
    if (selectedEventFilters.includes(value)) {
      setSelectedEventFilters(selectedEventFilters.filter(s => s !== value));
    } else {
      setSelectedEventFilters([...selectedEventFilters, value]);
    }
  }

  const handleStatusFilterChange = (value: string) => {
    if (selectedStatusFilters.includes(value)) {
      setSelectedStatusFilters(selectedStatusFilters.filter(s => s !== value));
    } else {
      setSelectedStatusFilters([...selectedStatusFilters, value]);
    }
  }

  return (
    <div>
      {(!data || !siteTypes || !siteStatuses || !table) &&
        <Card className="flex-1">
          <CardContent className="pt-6">
            <div className="flex gap-2 items-center justify-center rounded-md border py-80">
              <LoadingSpinner />
              <p className="font-semibold">Fetching Site Events...</p>
            </div>
          </CardContent>
        </Card>
      }

      {data && table && siteTypes && siteStatuses &&
        <>
          <Card className="flex-1">
            <CardContent className="pt-6">
              <div className="flex mb-4 items-center gap-2">
                <Filter
                  title="Event Type"
                  options={siteTypes.map(item => ({ label: item.type, value: item.id }))}
                  onFilterChange={handleEventFilterChange}
                  selectedValues={selectedEventFilters}
                />
                <Filter
                  title="Status"
                  options={siteStatuses.map(item => ({ label: item.status, value: item.id }))}
                  onFilterChange={handleStatusFilterChange}
                  selectedValues={selectedStatusFilters}
                />
                <Popover>
                  <PopoverTrigger asChild>
                    <Button
                      id="date"
                      variant={"outline"}
                      className={cn(
                        "justify-start text-left font-normal",
                        !date && "text-muted-foreground"
                      )}
                    >
                      <CalendarIcon className="mr-2 h-4 w-4" />
                      {date?.from ? (
                        date.to ? (
                          <>
                            {moment(date.from).format("MMM Do YY")} -{" "}
                            {moment(date.to).format("MMM Do YY")}
                          </>
                        ) : (
                          moment(date.from).format("MMM Do YY")
                        )
                      ) : (
                        <span>Pick a date</span>
                      )}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      initialFocus
                      mode="range"
                      defaultMonth={date?.from}
                      selected={date}
                      onSelect={setDate}
                      numberOfMonths={2}
                    />
                  </PopoverContent>
                </Popover>
                <div className="ml-auto flex items-center gap-2">
                  <Button onClick={openForm}>Add Event</Button>
                </div>
              </div>
              <SiteEventsTable
                table={table}
              />
            </CardContent>
          </Card>

          <Dialog open={open} onOpenChange={setOpen}>
            <DialogContent className="max-w-xl">
              <DialogHeader>
                <DialogTitle>{selectedEvent ? "Edit" : "New"} Site Event</DialogTitle>
              </DialogHeader>
              <SiteEventForm onClose={closeForm} onFormSuccess={onFormSuccess} siteEvent={selectedEvent} siteTypes={siteTypes} siteStatuses={siteStatuses} />
            </DialogContent>
          </Dialog>
        </>
      }
    </div >
  )
}

export default SiteEvents;