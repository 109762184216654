export type OrganizationType = {
  id: string
  email: string
  name: string
}

export type SiteType = {
  id: string
  site_id?: string
  organization_id: string
  email: string
  name: string
  default: boolean
}

export enum Roles {
  Owner = 'owner',
  Admin = 'admin',
  User = 'user'
}

export type UserType = {
  id: string
  organization_id: string
  site_id: string
  email: string
  first_name: string
  last_name: string
  role: Roles,
  roles?: {
    id: string
    role: string
  }
  sites: SiteType[]
  organization: OrganizationType
}