import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { SiteType } from "@/types/user";
import { useEffect, useState } from "react";
import { useSiteSelector } from "../SiteProvider";

interface SiteSelectorProps {
  userSites: SiteType[]
}

const SiteSelector = ({ userSites }: SiteSelectorProps) => {
  const { selectedSite, handleSiteChange } = useSiteSelector();
  const [sites, setSites] = useState<SiteType[] | undefined>();

  useEffect(() => {
    if (!userSites) return;
    setSites(userSites);
  }, [userSites]);

  if (!sites || !selectedSite) {
    return null;
  }

  const onSiteChange = (siteId: string) => {
    const site = userSites.find(site => site.id === siteId);
    if (site && handleSiteChange) {
      handleSiteChange(site);
    }
  }

  return (
    <Select defaultValue={selectedSite.id} onValueChange={onSiteChange}>
      <SelectTrigger className="w-[180px] p-0 px-2 py-1 h-auto bg-card font-semibold">
        <SelectValue placeholder="Select a site" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {sites.map(site => <SelectItem key={site.id} className="cursor-pointer" value={site.id}>{site.name}</SelectItem>)}
        </SelectGroup>
      </SelectContent>
    </Select>
  )
}

export default SiteSelector;