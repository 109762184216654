import React from 'react';
import './global.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ThemeProvider } from "@/components/ThemeProvider"
import AuthProvider from './components/AuthProvider';
import { ConfirmationDialogProvider } from './components/confirmDialog/ConfirmDialogProvider';
import { routes } from './routes';

const router = createBrowserRouter(routes);

function App() {
  return (
    <ThemeProvider defaultTheme="light" storageKey="site-theme">
      <AuthProvider>
        <ConfirmationDialogProvider>
          <RouterProvider router={router} />
        </ConfirmationDialogProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;