import { DataTable } from "@/components/table/DataTable";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { UserType } from "@/types/user";
import { supabase } from "@/lib/supabaseClient";
import { useEffect, useState } from "react";
import { siteCardColumns } from "./SiteCardColumns";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import { z } from "zod";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import { formSchema, InviteUserForm } from "./InviteUserForm";
import { OrganizationSite } from "../queries";
import { useAuth } from "@/components/AuthProvider";
import InviteUserDialog from "./InviteUserDialog";
import SiteNameInput from "./NewSiteInput";

interface SiteCardProps {
  site: OrganizationSite
}

const SiteCard = ({ site }: SiteCardProps) => {
  const { appUser } = useAuth();
  const [users, setUsers] = useState<UserType[]>();
  const [open, setOpen] = useState<boolean>(false);

  console.log(site)

  useEffect(() => {
    if (!site) return;

    const fetchSiteUsers = async () => {
      let { data, error } = await supabase
        .from('sites')
        .select("siteUsers:user_sites(*, users(*), roles(*))")
        .eq('id', site.id)
        .single()

      if (data && data.siteUsers) {
        const users = data.siteUsers.map(user => {
          return { ...user.users, roles: user.roles }
        });
        setUsers(users);
      }

      if (error) {
        alert(error.message)
      }
    }

    fetchSiteUsers();
  }, [site])

  return (
    <>
      <div className="flex flex-col gap-4">
        <Card>
          <CardHeader>
            <CardTitle>Site</CardTitle>
            <CardDescription>Site where you manage equipment</CardDescription>
          </CardHeader>
          <CardContent>
            <SiteNameInput site={site} />
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Users</CardTitle>
            <CardDescription>Team members here are Admin level users that have access to all sites within the org</CardDescription>
          </CardHeader>

          <CardContent>
            {!users &&
              <div className="flex gap-2 items-center justify-center rounded-md border py-6">
                <LoadingSpinner />
                <p className="font-semibold">Fetching Users...</p>
              </div>
            }
            {users &&
              <>
                <Button type="submit" onClick={() => setOpen(true)}>Add</Button>
                <DataTable
                  data={users}
                  columns={siteCardColumns}
                  hidePagination
                />
              </>
            }
          </CardContent>
        </Card>
      </div>

      <InviteUserDialog
        open={open}
        setOpen={setOpen}
        site={site}
      />
    </>
  )
}

export default SiteCard;