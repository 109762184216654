import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useLocation, useNavigate } from "react-router-dom";
import ComplianceConfig from "./configuration/ComplianceConfig";
import TestGroup from "./TestGroup";
import { Roles } from "@/types/user";
import { useAuth } from "@/components/AuthProvider";

const ComplianceTabs = () => {
  const { appUser } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const currentTab = location.pathname.endsWith("/configuration") ? "configuration" : "compliance";

  const handleTabChange = (value: string) => {
    navigate(value === "configuration" ? "/compliance/configuration" : "/compliance");
  };

  return (
    <div>
      <Tabs value={currentTab} onValueChange={handleTabChange}>
        <TabsList>
          <TabsTrigger value="compliance">Compliance</TabsTrigger>
          {(appUser?.role === Roles.Owner || appUser?.role === Roles.Admin) &&
            <TabsTrigger value="configuration">Configuration</TabsTrigger>
          }
        </TabsList>
        <div>
          <TabsContent value="compliance">
            <TestGroup />
          </TabsContent>
          <TabsContent value="configuration">
            <ComplianceConfig />
          </TabsContent>
        </div>
      </Tabs>
    </div>
  )
}

export default ComplianceTabs;