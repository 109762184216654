import { MaintenanceLog, NewMaintenanceLog } from "../maintenance/table/columns";
import { SiteEvent } from "../siteEvents/SiteEvents";
import { EquipmentAndLogs } from "./logic";
import { ReportGroup, dayNightTime } from "./Reports";
import moment from "moment";

/*
  range - 07/01/2024 - 13/01/2024

  cases
  date down 05/01/2024 but no up time
    included and down time calc from the 7th
  date down 05/01/2023 but no up time
    skipped as not the same year
  date down 05/01/2024 date up 10/01/2024
    included and calc from 07/01/2024 to 10/01/2024

  1. include all records where no up date
  2. include all records where up date and is before or equal the end
*/
const filterRecordsByDateRange = (data: any[], startDate: string, endDate: string) => {
  const startSplit = startDate.split("/").map(Number);
  const start = new Date(startSplit[2], startSplit[1] - 1, startSplit[0]);
  const endSplit = endDate.split("/").map(Number);
  const end = new Date(endSplit[2], endSplit[1] - 1, endSplit[0]);

  // only records with a date down
  const startedRecords = data.filter(record => record.date_down);

  const recordsInRange = startedRecords.filter(record => {
    if (!record.date_down || !record.time_down) return false;
    if (record.downtime_type === "N/A" || !record.downtime_type) return false;

    // date down
    const dateDownParts = (record.date_down as string).split('/');
    const dateDown = new Date(
      parseInt(dateDownParts[2]),
      parseInt(dateDownParts[1]) - 1,
      parseInt(dateDownParts[0])
    );

    // remove any records that dont occur in the same year
    if (dateDownParts[2] < startSplit[2].toString()) return false;

    if (dateDown >= start && dateDown <= end) {
      return true;
    }

    // no date up = machine still down
    if (!record.date_up && dateDown <= end) {
      return true
    }

    if (record.date_up) {
      // date up
      const dateUpParts = (record.date_up as string).split('/');
      const dateUp = new Date(
        parseInt(dateUpParts[2]),
        parseInt(dateUpParts[1]) - 1,
        parseInt(dateUpParts[0])
      );

      return dateUp >= start && dateUp <= end;
    }
  });

  return recordsInRange;
};

/*
  Given data and a date time range it should return data between those dates

  Maintence log
    - date_down: 11/11/2023
    - time_down: 10:00am
    - date_up: 12/11/2023
    - time_up: 10:00am

  Site Event
    - date_down: 11/11/2023
    - time_down: 03:00pm
    - date_up: 11/11/2023
    - time_up: 05:00pm

  Record should be return
*/
const filterRecordsByDateTimeRange = (data: any[], start: string, end: string) => {
  const rangeStart = moment(start, "YYYY-MM-DD hh:mm A");
  const rangeEnd = moment(end, "YYYY-MM-DD hh:mm A");

  const filteredRecords = data.filter(record => {
    if (!record.date_down || !record.time_down) return false;
    if (record.event_type === "N/A") return false;

    const recordStart = moment(`${record.date_down} ${record.time_down}`, "YYYY-MM-DD hh:mm A")
    const recordEnd = moment(`${record.date_up} ${record.time_up}`, "YYYY-MM-DD hh:mm A")

    // including any records where they are still ongoing (down but not up yet)
    if (!record.date_up) {
      if (recordEnd.isBefore(rangeEnd)) {
        return true;
      }
    }

    if (record.date_up) {
      // record start is the same or after the given start range and is the same or before the end
      if (rangeStart.isSameOrAfter(recordStart) && rangeEnd.isSameOrBefore(recordEnd)) {
        // console.log('Maintenance log happens within the range of a site event, so it is included')
        // console.log(`${recordStart.format("YYYY-MM-DD hh:mm A")} to ${recordEnd.format("YYYY-MM-DD hh:mm A")}`)
        return true;
      }

      if (recordStart.isSameOrAfter(rangeStart) && recordEnd.isSameOrBefore(rangeEnd)) {
        // console.log('Site event happens within the range of a maintance log, so it is included')
        // console.log(`${recordStart.format("YYYY-MM-DD hh:mm A")} to ${recordEnd.format("YYYY-MM-DD hh:mm A")}`)
        return true;
      }
    }

    return false;
  })

  return filteredRecords;
}

function calculateDayNightHours(startDateTime: any, endDateTime: any) {
  const dayStart = moment(startDateTime).set({ hour: 5, minute: 30, second: 0, millisecond: 0 });
  const dayEnd = moment(startDateTime).set({ hour: 17, minute: 30, second: 0, millisecond: 0 });
  const nightStart = moment(startDateTime).set({ hour: 17, minute: 30, second: 0, millisecond: 0 });
  const nightEnd = moment(startDateTime).add(1, 'day').set({ hour: 5, minute: 30, second: 0, millisecond: 0 });

  let dayHours = 0;
  let nightHours = 0;
  const timeArray: dayNightTime[] = [];

  let currentMoment = moment(startDateTime);

  // Calculate day and night hours for each day within the range
  // !!! does count night if record starts at < 5:30am
  while (currentMoment.isBefore(endDateTime)) {
    const nextDayStart = moment.max(currentMoment, dayStart);
    const nextNightStart = moment.max(currentMoment, nightStart);
    const nextDayEnd = moment.min(moment(endDateTime), dayEnd);
    const nextNightEnd = moment.min(moment(endDateTime), nightEnd);
    const date = {
      date: currentMoment.format("YYYY-MM-DD hh:mm A"),
      day: 0,
      night: 0
    }

    // if start is the am before day start (e.g. 1:00am to 5:30am) counts as night hours
    if (currentMoment.isBefore(nextDayStart)) {
      nightHours += nextDayStart.diff(currentMoment, 'hours', true);
      timeArray.push({
        date: currentMoment.format("YYYY-MM-DD hh:mm A") + " to " + nextDayStart.format("YYYY-MM-DD hh:mm A"),
        day: 0,
        night: nextDayStart.diff(currentMoment, 'hours', true)
      })
    }

    // Calculate day hours
    if (nextDayStart.isBefore(nextDayEnd)) {
      dayHours += nextDayEnd.diff(nextDayStart, 'hours', true);
      date.day = nextDayEnd.diff(nextDayStart, 'hours', true);
      date.date = currentMoment.format("YYYY-MM-DD hh:mm A") + " to " + nextDayEnd.format("YYYY-MM-DD hh:mm A")
    }

    // Calculate night hours
    if (nextNightStart.isBefore(nextNightEnd)) {
      nightHours += nextNightEnd.diff(nextNightStart, 'hours', true);
      date.night = nextNightEnd.diff(nextNightStart, 'hours', true);
      date.date = currentMoment.format("YYYY-MM-DD hh:mm A") + " to " + nextNightEnd.format("YYYY-MM-DD hh:mm A")
    }

    timeArray.push(date);

    // Move to the next day
    dayStart.add(1, 'day');
    dayEnd.add(1, 'day');
    nightStart.add(1, 'day');
    nightEnd.add(1, 'day');
    currentMoment.add(1, 'day').hour(5).minute(30).second(0);
  }

  return { dayHours, nightHours, timeArray };
}

const calcDayNightHours = (record: MaintenanceLog | NewMaintenanceLog, rangeStart: string, rangeEnd: string) => {
  let dateDown = record.date_down;
  let timeDown = record.time_down;
  let dateUp = record.date_up;
  let timeUp = record.time_up;

  // date down and up down provided, no times
  if ((dateDown && dateUp) && (!timeDown || !timeUp)) {
    timeUp = "05:30am"
    timeDown = "05:30am"
  }

  if (dateDown && !dateUp) {
    // add extra day to account for nightshift of last day
    dateUp = moment(rangeEnd, "YYYY-MM-DD").add(1, 'day').format("YYYY-MM-DD");
    if (!timeUp) {
      timeUp = "05:30 am";
    }
  }

  // record started before reporting period, including
  if (moment(dateDown, "YYYY-MM-DD").isBefore(moment(rangeStart, "YYYY-MM-DD"))) {
    dateDown = rangeStart;
    timeDown = "05:30 am";
  }

  // record ended after reporting period, including
  if (moment(dateUp, "YYYY-MM-DD").isAfter(moment(rangeEnd, "YYYY-MM-DD"))) {
    dateUp = moment(rangeEnd, "YYYY-MM-DD").add(1, "day").format("YYYY-MM-DD");
    timeUp = "05:30 am";
  }

  const start = dateDown + ' ' + timeDown;
  const end = dateUp + ' ' + timeUp;
  const startDate = moment(start, "YYYY-MM-DD hh:mm A");
  const endDate = moment(end, "YYYY-MM-DD hh:mm A");

  return calculateDayNightHours(startDate, endDate);
}

const calcSiteEventHours = (siteEvent: SiteEvent, maintenaceLog: MaintenanceLog | NewMaintenanceLog) => {
  const maintenaceStart = moment(`${maintenaceLog.date_down} ${maintenaceLog.time_down}`, "YYYY-MM-DD hh:mm A");
  const maintenaceEnd = moment(`${maintenaceLog.date_up} ${maintenaceLog.time_up}`, "YYYY-MM-DD hh:mm A");
  let siteStart = moment(`${siteEvent.date_down} ${siteEvent.time_down}`, "YYYY-MM-DD hh:mm A");
  let siteEnd = moment(`${siteEvent.date_up} ${siteEvent.time_up}`, "YYYY-MM-DD hh:mm A");

  if (siteStart.isSameOrAfter(maintenaceStart) && siteEnd.isSameOrBefore(maintenaceEnd)) {
    // console.log('site event happens within maintance range', calculateDayNightHours(siteStart, siteEnd))
    // console.log(`${siteStart.format("YYYY-MM-DD hh:mm A")} to ${siteEnd.format("YYYY-MM-DD hh:mm A")}`)
    return calculateDayNightHours(siteStart, siteEnd);
  }

  // is site evnt starts before maintenance, move it to maintenace start
  if (siteStart.isBefore(maintenaceStart)) {
    siteStart = maintenaceStart;
  }

  // end doesn't exist or is after maintenance
  if (!siteEnd.isValid() || siteEnd.isAfter(maintenaceEnd)) {
    siteEnd = maintenaceEnd;
  }

  return calculateDayNightHours(siteStart, siteEnd);
}

function calculateDifferences(arr: ReportGroup["smu"]) {
  const differences = [];
  for (let i = 1; i < arr.length; i++) {
    const diff = Number(arr[i].value || 0) - Number(arr[i - 1].value || 0);
    const date = arr[i].date;
    if (diff > 0) {
      differences.push({ date, hours: diff });
    }
  }
  return differences;
}

function calculateDifferencesDB(arr: EquipmentAndLogs["smuData"]) {
  const differences = [];
  for (let i = 1; i < arr.length; i++) {
    const diff = Number(arr[i].value || 0) - Number(arr[i - 1].value || 0);
    const date = arr[i].smu_date.date;
    if (diff > 0) {
      differences.push({ date, hours: diff });
    }
  }
  return differences;
}

/*
  Adjust a maintenance logs date up an down to suit reporting period
*/
const adjustDateTime = (record: MaintenanceLog, rangeStart: string, rangeEnd: string) => {
  let dateDown = record.date_down;
  let timeDown = record.time_down;
  let dateUp = record.date_up;
  let timeUp = record.time_up;

  // date down and up down provided, no times
  // really this shouldnt be allowed, todo - test if this causes issues
  if ((dateDown && dateUp) && (!timeDown || !timeUp)) {
    timeUp = "05:30 am"
    timeDown = "05:30 am"
  }

  // if no date up provided, we assume the equipment is still down
  // so the date up is adjusted to end of reporting range
  if (dateDown && !dateUp) {
    // add extra day to account for nightshift of last day
    dateUp = moment(rangeEnd, "YYYY-MM-DD").add(1, 'day').format("YYYY-MM-DD");
    if (!timeUp) {
      timeUp = "05:30 am";
    }
  }

  // record started before reporting period
  // adjust the date down to start of reporting period
  if (moment(dateDown, "YYYY-MM-DD").isBefore(moment(rangeStart, "YYYY-MM-DD"))) {
    dateDown = rangeStart;
    timeDown = "05:30 am";
  }

  // record ended after reporting period
  // adjust the date up to end of reporting period
  if (moment(dateUp, "YYYY-MM-DD").isAfter(moment(rangeEnd, "YYYY-MM-DD"))) {
    dateUp = moment(rangeEnd, "YYYY-MM-DD").add(1, "day").format("YYYY-MM-DD");
    timeUp = "05:30 am";
  }

  const start = dateDown + ' ' + timeDown;
  const end = dateUp + ' ' + timeUp;

  return {
    start,
    end
  };
}

const adjustSiteEventDateTime = (siteEvent: SiteEvent, maintenaceLog: MaintenanceLog) => {
  const maintenaceStart = moment(`${maintenaceLog.date_down} ${maintenaceLog.time_down}`, "YYYY-MM-DD hh:mm A");
  const maintenaceEnd = moment(`${maintenaceLog.date_up} ${maintenaceLog.time_up}`, "YYYY-MM-DD hh:mm A");
  let siteStart = moment(`${siteEvent.date_down} ${siteEvent.time_down}`, "YYYY-MM-DD hh:mm A");
  let siteEnd = moment(`${siteEvent.date_up} ${siteEvent.time_up}`, "YYYY-MM-DD hh:mm A");

  // is site event starts before maintenance, move it to maintenace start
  if (siteStart.isBefore(maintenaceStart)) {
    siteStart = maintenaceStart;
  }

  // end doesn't exist or is after maintenance
  if (!siteEnd.isValid() || siteEnd.isAfter(maintenaceEnd)) {
    siteEnd = maintenaceEnd;
  }

  return {
    start: siteStart.format("YYYY-MM-DD hh:mm A"),
    end: siteEnd.format("YYYY-MM-DD hh:mm A"),
  };
}

/*
  Calculate the hours that overlap between rangeOne and rangeTwo
*/
const calcOverlapHours = (rangeOneStart: string, rangeOneEnd: string, rangeTwoStart: string, rangeTwoEnd: string,) => {
  const maintenaceStart = moment(rangeOneStart, "YYYY-MM-DD hh:mm A");
  const maintenaceEnd = moment(rangeOneEnd, "YYYY-MM-DD hh:mm A");
  let siteStart = moment(rangeTwoStart, "YYYY-MM-DD hh:mm A");
  let siteEnd = moment(rangeTwoEnd, "YYYY-MM-DD hh:mm A");

  if (siteStart.isSameOrAfter(maintenaceStart) && siteEnd.isSameOrBefore(maintenaceEnd)) {
    // console.log('site event happens within maintance range', calculateDayNightHours(siteStart, siteEnd))
    // console.log(`${siteStart.format("YYYY-MM-DD hh:mm A")} to ${siteEnd.format("YYYY-MM-DD hh:mm A")}`)
    return calculateDayNightHours(siteStart, siteEnd);
  }

  // is site evnt starts before maintenance, move it to maintenace start
  if (siteStart.isBefore(maintenaceStart)) {
    siteStart = maintenaceStart;
  }

  // end doesn't exist or is after maintenance
  if (!siteEnd.isValid() || siteEnd.isAfter(maintenaceEnd)) {
    siteEnd = maintenaceEnd;
  }

  return calculateDayNightHours(siteStart, siteEnd);
}

const countRecordByType = (records: NewMaintenanceLog[] | SiteEvent[]) => {
  const typeCounts: { [type: string]: number; } = {};

  records.forEach(record => {
    const type = (record as NewMaintenanceLog).type?.name || (record as SiteEvent).type;

    if (type) {
      typeCounts[type] = (typeCounts[type] || 0) + 1;
    }
  });
  const typeCountArr = Object.keys(typeCounts).map(key => {
    return {
      name: key,
      value: typeCounts[key]
    }
  });

  return typeCountArr
}

export {
  adjustDateTime,
  adjustSiteEventDateTime,
  calculateDayNightHours,
  calcOverlapHours,
  filterRecordsByDateRange,
  filterRecordsByDateTimeRange,
  calcSiteEventHours,
  calcDayNightHours,
  calculateDifferences,
  calculateDifferencesDB,
  countRecordByType
}