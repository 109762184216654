import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Form, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { useEffect } from "react";
import { ComplianceItem } from "../../types";

interface ComplianceItemFormProps {
  onClose: () => void;
  onFormSuccess: (values: z.infer<typeof ComplianceItemFormSchema>) => void;
  siteId: string | undefined;
  complianceItem: ComplianceItem | null;
}

export const ComplianceItemFormSchema = z.object({
  id: z.string().optional(),
  site_id: z.string({
    required_error: 'site id is required'
  }),
  name: z.string({
    required_error: 'name is required'
  })
});

const ComplianceItemForm = ({ onClose, onFormSuccess, siteId, complianceItem }: ComplianceItemFormProps) => {
  const form = useForm<z.infer<typeof ComplianceItemFormSchema>>({
    resolver: zodResolver(ComplianceItemFormSchema),
    defaultValues: {
      site_id: siteId,
    }
  })

  useEffect(() => {
    if (complianceItem) {
      form.setValue("name", complianceItem.name);
      form.setValue("id", complianceItem.id);
    }
  }, [complianceItem, form]);

  const onSubmit = async (values: z.infer<typeof ComplianceItemFormSchema>) => {
    onFormSuccess(values);
  }

  return (
    <Form {...form}>
      <form className="space-y-2">
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem className="flex-1">
              <FormLabel>Name</FormLabel>
              <Input {...field} />
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex">
          <Button type="button" onClick={onClose} variant="ghost">Cancel</Button>
          <Button type="submit" onClick={form.handleSubmit(onSubmit)} className="ml-auto">Submit</Button>
        </div>
      </form>
    </Form>
  )
}

export default ComplianceItemForm;

