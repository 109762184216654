import { AnalyticsObj } from "../overview/Overview"

export function ChartTest({ chartData }: { chartData: AnalyticsObj[] }) {
  const maxValue = Math.max(...chartData.map(chart => chart.value));

  return (
    <div className="space-y-2">
      {chartData && chartData.map((chart) => {
        const normalizedPercentage = (chart.value / maxValue) * 100;

        return (
          <div key={chart.name} className="">
            <p className="w-full text-sm">{chart.name} ({chart.value})</p>
            <div className="flex-1 h-3 bg-gray-200 rounded overflow-hidden">
              <div
                className="h-full bg-blue-500 rounded"
                style={{ width: `${normalizedPercentage}%` }}
              ></div>
            </div>
          </div>
        );
      })}
    </div>
  )
}