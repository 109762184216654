import { supabase } from '@/lib/supabaseClient';

export type OrganizationSite = {
  id: string
  name: string
  organization_id: string
}

const fetchSites = async (organizationId: string | undefined, siteId?: string) => {
  if (!organizationId) throw Error("organizationId requied to fetch sites");

  let query = supabase
    .from('sites')
    .select("*")
    .eq('organization_id', organizationId)

  if (siteId) {
    query = query.eq('id', siteId);
  }

  const { data, error } = await query;
  return { data, error };
}

const fetchOrganization = async (organizationId: string | undefined) => {
  if (!organizationId) throw Error("organizationId requied to fetch organization");

  let query = await supabase
    .from('organizations')
    .select(`
      *,
      users!inner(*)
    `)
    .eq('id', organizationId)
    .eq('users.is_org_owner', true)
    .single();  
    
  const { data, error } = await query;
  return { data, error };
}

export { fetchSites, fetchOrganization }