import { ComplianceItem } from "./types"

interface GroupProps {
  components?: ComplianceItem[]
  header?: boolean
}

const HeaderCol = ({ header, components }: GroupProps) => {
  return (
    <div className="flex flex-col w-80 shrink-0 text-sm">
      <div className="border px-2 bg-muted text-center">
        <p className="opacity-0">Site ID</p>
      </div>

      <div className="border px-2">
        <p>Current SMU</p>
      </div>

      <div className="flex border px-2 text-center">
        <div className="w-1/3">
          <p className="opacity-0">No value</p>
        </div>
        <div className="w-1/3">
          <p className="opacity-0">No value</p>
        </div>
        <div className="w-1/3">
          <p className="opacity-0">No value</p>
        </div>
      </div>

      <div>
        {header && components && components.map(component => {
          return (
            <div className="border px-2 flex text-center">
              <p className="text-nowrap">{component.name}</p>
            </div>
          )
        })}
      </div>
    </div >
  )
}

export default HeaderCol;