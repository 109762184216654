import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from "./header/Header";
import { Toaster } from "./ui/toaster"
import SiteSelectProvider from './SiteProvider';

function Layout() {
  return (
    <SiteSelectProvider>
      <Header />
      <div className="overflow-y-auto h-[calc(100vh-102px)]">
        <div className="p-6 w-full">
          <Outlet />
        </div>
      </div>
      <Toaster />
    </SiteSelectProvider>
  );
}

export default Layout;