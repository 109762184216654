import moment from "moment"
import { cn } from "@/lib/utils"
import { Compliance, ComplianceItem, Equipment } from "./types";

interface GroupProps {
  components?: ComplianceItem[]
  equipment: Equipment
  header?: boolean
  onCellClick: (record: Compliance, component: ComplianceItem, equipment: Equipment) => void
}

export const getBadgeColor = (value: number, high: number, medium: number, low: number) => {
  switch (true) {
    case value <= high:
      return 'bg-red-500 text-white'
    case value <= medium:
      return 'bg-orange-500 text-white'
    case value <= low:
      return 'bg-yellow-500 text-white'
    default:
      return ''
  }
}

const EquipmentCol = ({ header, components, equipment, onCellClick }: GroupProps) => {
  return (
    <div className="flex flex-col w-80 shrink-0 text-sm">
      <div className="border px-2 bg-muted text-center">
        <p>{equipment.site_id}</p>
      </div>

      <div className="border px-2">
        <p className="text-center">{equipment.smu || "123123"}</p>
      </div>

      <div className="flex border px-2 text-center">
        <div className="w-1/3 [&:not(:last-child)]:border-r">
          <p>Last Done</p>
        </div>
        <div className="w-1/3 [&:not(:last-child)]:border-r">
          <p>Until Due</p>
        </div>
        <div className="w-1/3 [&:not(:last-child)]:border-r">
          <p>Due</p>
        </div>
      </div>

      <div>
        {components && components.map(component => {
          const record = equipment.compliance_item_equipment_entry.find(item => {
            return item.compliance_item_group.compliance_component_id === component.id;
          });

          if (!record) {
            return (
              <div className="flex border px-2 text-center bg-muted dark:bg-background pointer-events-none">
                <div className="w-1/3 [&:not(:last-child)]:border-r">
                  <p className="opacity-0">no value</p>
                </div>
                <div className="w-1/3 [&:not(:last-child)]:border-r">
                  <p className="opacity-0">no value</p>
                </div>
                <div className="w-1/3 [&:not(:last-child)]:border-r">
                  <p className="opacity-0">no value</p>
                </div>
              </div>
            )
          }

          let dueDate = "";
          let untilDue = "";
          let lastDone = record.last_done;

          if (lastDone) {
            if (record.compliance_item_group.interval_type === "month") {
              dueDate = moment(record.last_done, "YYYY-MM-DD").add(record.compliance_item_group.interval, "months").format("DD-MMM-YY");
              untilDue = moment(dueDate, "DD-MMM-YY").diff(moment(), 'days').toString();
              lastDone = moment(lastDone, "YYYY-MM-DD").format("DD-MMM-YY")
            }

            if (record.compliance_item_group.interval_type === "hour") {
              dueDate = (parseInt(record.last_done) + parseInt(record.compliance_item_group.interval)).toString();
              untilDue = (parseInt(dueDate) - parseInt(equipment.smu)).toString();
            }
          }

          const alertHigh: number = parseInt(record.compliance_item_group.alert_high);
          const alertMedium: number = parseInt(record.compliance_item_group.alert_medium);
          const alertLow: number = parseInt(record.compliance_item_group.alert_low);
          const color = getBadgeColor(parseInt(untilDue), alertHigh, alertMedium, alertLow);

          return (
            <div className={"flex border px-2 text-center hover:bg-muted cursor-pointer"} onClick={() => onCellClick(record, component, equipment)}>
              <div className="w-1/3 [&:not(:last-child)]:border-r">
                <p>{lastDone || "-"}</p>
              </div>
              <div className={cn("w-1/3 [&:not(:last-child)]:border-r", color)}>
                <p>{untilDue || "-"}</p>
              </div>
              <div className="w-1/3 [&:not(:last-child)]:border-r">
                <p>{dueDate || "-"}</p>
              </div>
            </div>
          )
        })}
      </div>
    </div >
  )
}

export default EquipmentCol;