import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import { Machine } from '@/pages/machines/list/table/columns';
import { Checkbox } from "@/components/ui/checkbox"
import { ScrollArea } from "@/components/ui/scroll-area"
import { cn } from "@/lib/utils"
import { useEffect } from "react"
import { ComplianceItemGroup } from "../../types"

interface GroupFormProps {
  onClose: () => void
  onFormSuccess: (values: z.infer<typeof formSchema>) => void
  complianceItemGroup?: ComplianceItemGroup
  complianceItemGroups: ComplianceItemGroup[],
  equipment: Machine[]
}

export const formSchema = z.object({
  id: z.string().optional(),
  name: z.string({
    required_error: 'name is required'
  }),
  interval_type: z.string({
    required_error: 'interval type is required'
  }),
  interval: z.string({
    required_error: 'interval type is required'
  }),
  alert_low: z.string({
    required_error: 'interval type is required'
  }),
  alert_medium: z.string({
    required_error: 'interval type is required'
  }),
  alert_high: z.string({
    required_error: 'interval type is required'
  }),
  equipment: z.array(z.string())
});

export function GroupForm({ onClose, onFormSuccess, complianceItemGroup, complianceItemGroups, equipment }: GroupFormProps) {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      equipment: []
    },
  })

  useEffect(() => {
    if (!complianceItemGroup || !form) return;
    const equipmentIds = complianceItemGroup.equipment.map(equipment => equipment.equipment_id) || [];
    form.reset({
      id: complianceItemGroup.id,
      name: complianceItemGroup.name,
      interval_type: complianceItemGroup.interval_type,
      interval: complianceItemGroup.interval.toString(),
      alert_low: complianceItemGroup.alert_low.toString(),
      alert_medium: complianceItemGroup.alert_medium.toString(),
      alert_high: complianceItemGroup.alert_high.toString(),
      equipment: equipmentIds
    })
  }, [form, complianceItemGroup])

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    onFormSuccess(values);
    console.log(values);
  }

  const handleClose = () => {
    form.reset();
    onClose();
  }

  const handleCheckboxChange = (checkedValue: string) => {
    const currentValue = form.getValues("equipment") || [];
    if (currentValue.includes(checkedValue)) {
      form.setValue(
        "equipment",
        currentValue.filter(value => value !== checkedValue)
      );
    } else {
      form.setValue(
        "equipment",
        [...currentValue, checkedValue]
      );
    }
  };

  const selectedEquipment = complianceItemGroups.map(group => group.equipment.map(equipment => equipment.equipment_id)).flat();

  const isEquipmentDisabled = (id: string) => {
    if (complianceItemGroup) {
      const complianceEquipment = complianceItemGroup.equipment.map(equipment => equipment.equipment_id);
      const equipmentInOtherGroups = selectedEquipment.filter(equipment => !complianceEquipment.includes(equipment));
      return equipmentInOtherGroups.includes(id);;
    }

    return selectedEquipment.includes(id);
  }

  return (
    <Form {...form}>
      <form className="space-y-2">
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem className="flex-1">
              <FormLabel>Name</FormLabel>
              <Input placeholder="Enter a group name" {...field} />
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="interval_type"
          render={({ field }) => (
            <FormItem className="flex-1">
              <FormLabel>Interval Type</FormLabel>
              <Select onValueChange={field.onChange} value={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select an interval type" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem key="Hour" value="hour">Hour</SelectItem>
                  <SelectItem key="Month" value="month">Month</SelectItem>
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="interval"
          render={({ field }) => (
            <FormItem className="flex-1">
              <FormLabel>Interval</FormLabel>
              <Input placeholder="Enter a interval" {...field} />
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="alert_low"
          render={({ field }) => (
            <FormItem className="flex-1">
              <FormLabel>Alert Low</FormLabel>
              <Input placeholder="Enter a alert value" {...field} />
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="alert_medium"
          render={({ field }) => (
            <FormItem className="flex-1">
              <FormLabel>Alert Medium</FormLabel>
              <Input placeholder="Enter a alert value" {...field} />
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="alert_high"
          render={({ field }) => (
            <FormItem className="flex-1">
              <FormLabel>Alert High</FormLabel>
              <Input placeholder="Enter a alert value" {...field} />
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="equipment"
          render={({ field }) => (
            <FormItem className="flex-1">
              <FormLabel>equipment</FormLabel>
              <ScrollArea className="h-72 rounded-sm border">
                <ul>
                  {equipment.map((machine) => (
                    <li className={cn("border-b p-2 flex items-center text-sm gap-4", isEquipmentDisabled(machine.id || "") ? "bg-gray-500" : "hover:bg-muted cursor-pointer")} onClick={(e) => {
                      if (e.currentTarget !== e.target) {
                        return;
                      }
                      if (isEquipmentDisabled(machine.id || "")) return;
                      handleCheckboxChange(machine.id || "")
                    }}>
                      <Checkbox
                        id={machine.id}
                        value={machine.id}
                        disabled={isEquipmentDisabled(machine.id || "")}
                        checked={field.value.includes(machine.id || "")}
                        onCheckedChange={() =>
                          handleCheckboxChange(machine.id || "")
                        }
                      />
                      {machine.site_id}
                    </li>
                  ))}
                </ul>
              </ScrollArea>
            </FormItem>
          )}
        />
        <div className="flex">
          <Button type="button" onClick={handleClose} variant="ghost">Cancel</Button>
          <Button type="submit" onClick={form.handleSubmit(onSubmit)} className="ml-auto">Submit</Button>
        </div>
      </form >
    </Form >
  )
}