import { ColumnDef } from "@tanstack/react-table"
import { SiteEvent } from "../SiteEvents"
import moment from "moment"
import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import { DeleteIcon, EditIcon, PencilIcon, Trash, Trash2Icon, TrashIcon } from "lucide-react"
import { ReadMore } from "@/components/ReadMore"

const statusBadeColourMapping = (status: string) => {
  switch (status) {
    case "Complete":
    case "Monitor":
      // return "green"
      return "bg-green-500"
    case "In Progress":
    case "Wait - Parts":
    case "Wait - Labour":
    case "Wait - Client":
    case "Wait - Scope":
      // return "blue"
      return "bg-blue-500"
    case "Next PM":
    case "New Line Started":
      // return "orange"
      return "bg-orange-500"
    default:
      return ""
  }
}

interface ColumnProps {
  onEdit: (value: SiteEvent) => void
  onDelete: (value: SiteEvent) => void
}

export const columns = ({ onEdit, onDelete }: ColumnProps): ColumnDef<SiteEvent>[] => [
  {
    accessorKey: "description",
    header: "Description",
    size: 500,
    cell: ({ row }) => {
      const length = 100;
      const description: string = row.getValue('description');
      // return description && description.length > length ? description.substring(0, length) + "..." : description
      return <ReadMore id={Math.random() + ''} text={description} amountOfWords={20} />
      return row.getValue('description');
    },
  },
  {
    accessorKey: "type",
    header: "Event Type",
  },
  {
    accessorKey: "status",
    header: "Status",
    cell: ({ row }) => {
      const status: string = row.getValue('status');
      // return <Badge variant={statusBadeColourMapping(status)}>{status}</Badge>
      const color = statusBadeColourMapping(status);
      return <Badge className={color} variant={color != '' ? 'default' : 'outline'}>{status}</Badge>
    },
    size: 125,
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id))
    }
  },
  {
    accessorKey: "date_down",
    header: "Date Down",
    cell: ({ row }) => {
      const date: string = row.getValue('date_down');
      const time: string = row.getValue('time_down');
      const formattedDate = moment(date, 'YYYY-MM-DD').format("DD/MM/yyyy");
      const formattedTime = moment(time, 'HH:mm').format('hh:mm A');

      if (date) {
        return formattedDate + ' ' + formattedTime;
      }
      return 'No date set';
    }
  },
  {
    accessorKey: "time_down",
    header: "Time Down",
  },
  {
    accessorKey: "date_up",
    header: "Date Up",
    cell: ({ row }) => {
      const date: string = row.getValue('date_up');
      const time: string = row.getValue('time_up');
      const formattedDate = moment(date, 'YYYY-MM-DD').format("DD/MM/yyyy");
      const formattedTime = moment(time, 'HH:mm').format('hh:mm A');

      if (date) {
        return formattedDate + ' ' + formattedTime;
      }

      return 'No date set';
    }
  },
  {
    accessorKey: "time_up",
    header: "Time Up",
  },
  {
    header: "Downtime",
    cell: ({ row }) => {
      if (!row.getValue('date_down') || !row.getValue('time_down') || !row.getValue('date_up') || !row.getValue('time_up')) {
        return '-'
      }

      const dateDown = row.getValue('date_down');
      const timeDown = row.getValue('time_down');
      const dateUp = row.getValue('date_up');
      const timeUp = row.getValue('time_up');

      const dateTimeDown = moment(`${dateDown} ${timeDown}`, "YYYY-MM-DD HH:mm");
      const dateTimeUp = moment(`${dateUp} ${timeUp}`, "YYYY-MM-DD HH:mm");

      // Calculate the time difference in milliseconds
      const timeDiffInMilliseconds = dateTimeUp.diff(dateTimeDown);

      // Convert the time difference to hours
      const timeDiffInHours = moment.duration(timeDiffInMilliseconds).asHours();

      return timeDiffInHours.toFixed(1) + 'hrs';
    },
    size: 100,
  },
  {
    id: 'actions',
    cell: ({ row, table }) => {
      return (
        <div className="flex justify-end gap-1">
          <Button variant="ghost" size="sm" className="h-7 gap-1" onClick={() => {
            onEdit(row.original)
          }}>
            <PencilIcon className="h-3.5 w-3.5" />
          </Button>
          <Button variant="ghost" size="sm" className="h-7 gap-1" onClick={() => {
            onDelete(row.original)
          }}>
            <TrashIcon className="h-3.5 w-3.5" />
          </Button>
        </div>
      )
    },
    maxSize: 80,
  }
]
