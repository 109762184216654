import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { z } from "zod";
import { useSiteSelector } from "@/components/SiteProvider";
import ComplianceItemForm, { ComplianceItemFormSchema } from "./ComplianceItemForm";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { EllipsisIcon } from "lucide-react";
import { ComplianceItem } from "../../types";
import { useComplianceItems } from "./useComplianceItems";

interface ComplianceItemsListProps {
  selectedGroup: ComplianceItem | undefined;
  setSelectedGroup: (group: ComplianceItem | undefined) => void;
}

const ComplianceItemsList = ({ selectedGroup, setSelectedGroup }: ComplianceItemsListProps) => {
  const { selectedSite } = useSiteSelector();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [itemToEdit, setItemToEdit] = useState<ComplianceItem | null>(null);
  const {
    complianceItems,
    handleAddOrUpdateItem,
    handleDeleteItem
  } = useComplianceItems(selectedSite?.id, selectedGroup, setSelectedGroup);

  const handleFormSubmit = async (values: z.infer<typeof ComplianceItemFormSchema>) => {
    const payload = {
      site_id: values.site_id,
      name: values.name,
      ...(values.id && { id: values.id }),
    };

    const success = await handleAddOrUpdateItem(payload);
    if (success) {
      closeDialog();
    }
  };

  const handleItemClick = (item: ComplianceItem) => setSelectedGroup(item);
  const isActiveItem = (item: ComplianceItem) => selectedGroup?.id === item.id ? "bg-muted" : "";

  const openDialog = (item: ComplianceItem | null = null) => {
    setItemToEdit(item);
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setItemToEdit(null);
  };

  return (
    <>
      <div className="flex flex-col border">
        <ul>
          <li className="text-sm border-b py-1 px-4 font-medium">
            <div className="flex w-full text-sm font-medium">
              <p>Compliance Items</p>
              <Button size="sm" className="ml-auto h-auto" onClick={() => openDialog()}>Add Item</Button>
            </div>
          </li>
        </ul>
        <div className="h-full overflow-y-scroll">
          <ul>
            {complianceItems && complianceItems.map(item => (
              <ComplianceListItem
                key={item.id}
                item={item}
                isActive={isActiveItem(item)}
                onItemClick={handleItemClick}
                onEditClick={() => openDialog(item)}
                onDeleteClick={() => handleDeleteItem(item)}
              />
            ))}
          </ul>
        </div>
      </div >

      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="max-w-xl">
          <DialogHeader>
            <DialogTitle>{itemToEdit ? 'Edit' : 'New'} Compliance Item</DialogTitle>
          </DialogHeader>
          <ComplianceItemForm
            onClose={closeDialog}
            onFormSuccess={handleFormSubmit}
            siteId={selectedSite?.id}
            complianceItem={itemToEdit}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

interface ComplianceListItemProps {
  item: ComplianceItem;
  isActive: string;
  onItemClick: (item: ComplianceItem) => void;
  onEditClick: () => void;
  onDeleteClick: () => void;
}

const ComplianceListItem = ({ item, isActive, onItemClick, onEditClick, onDeleteClick }: ComplianceListItemProps) => {
  return (
    <li
      className={cn(isActive, "flex items-center justify-between py-1 px-4 hover:bg-muted cursor-pointer border-b last:border-b-0")}
      onClick={() => onItemClick(item)}
    >
      <span className="text-sm">{item.name}</span>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="h-8 w-8 p-0">
            <span className="sr-only">Open menu</span>
            <EllipsisIcon className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem onClick={onEditClick}>Edit</DropdownMenuItem>
          <DropdownMenuItem onClick={onDeleteClick}>Delete</DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </li>
  );
};

export default ComplianceItemsList;
