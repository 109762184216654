import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import OverviewBarChart from "../overview/BarChart";
import { countRecordByType } from "../reporting/util";
import { useEffect, useState } from "react";
import { useSiteSelector } from "@/components/SiteProvider";
import { fetchSiteEvents } from "./queries";
import { SiteEvent } from "./SiteEvents";

const SiteAnalytics = () => {
  const { selectedSite } = useSiteSelector();
  const [siteEvents, setSiteEvents] = useState<SiteEvent[]>()

  useEffect(() => {
    if (!selectedSite) return;

    const getSiteEvents = async () => {
      const { data, error } = await fetchSiteEvents(selectedSite.id);

      if (data) {
        setSiteEvents(data);
      }

      if (error) {
        alert(error.message)
      }
    }

    getSiteEvents();
  }, [selectedSite]);

  return (
    <div>
      {!siteEvents &&
        <div className="w-full flex justify-center">
          <LoadingSpinner />
        </div>
      }

      {siteEvents &&
        <>
          <div className="grid grid-cols-3 gap-4 pt-6">
            <Card>
              <CardHeader>
                <CardTitle>Site Event Types</CardTitle>
              </CardHeader>
              <CardContent>
                <OverviewBarChart data={countRecordByType(siteEvents)} />
              </CardContent>
            </Card>
          </div>
        </>
      }
    </div>
  )
}

export default SiteAnalytics;