import { useSiteSelector } from "@/components/SiteProvider";
import { useEffect, useState } from "react";
import { Machine } from "../../machines/list/table/columns";
import ComplianceItemsList from "./items/ComplianceItemsList";
import ComplianceGroups from "./groups/Groups";
import { fetchAllEquipment } from "../../machines/queries";
import { ComplianceItem } from "../types";

const ComplianceConfig = () => {
  const { selectedSite } = useSiteSelector();
  const [selectedItem, setSelectedItem] = useState<ComplianceItem>();
  const [equipment, setEquipment] = useState<Machine[]>();

  useEffect(() => {
    if (!selectedSite) return;

    const getEquipment = async () => {
      const { data: equipment } = await fetchAllEquipment(selectedSite.id);
      setEquipment(equipment as Machine[])
    }

    getEquipment();
  }, [selectedSite])

  return (
    <div className="flex bg-card min-h-[20vh] max-h-[85vh]">
      <ComplianceItemsList
        selectedGroup={selectedItem}
        setSelectedGroup={setSelectedItem}
      />
      <ComplianceGroups
        selectedItemId={selectedItem?.id}
        equipment={equipment || []}
      />
    </div>
  )
}

export default ComplianceConfig;