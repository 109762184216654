import { supabase } from "@/lib/supabaseClient";

export type CreateComplianceGroupPayload = {
  id?: string;
  site_id: string;
  compliance_component_id: string;
  name: string;
  interval_type: string;
  interval: string;
  alert_low: string;
  alert_medium: string;
  alert_high: string;
}

export const fetchComplianceItems = async (siteId: string | undefined) => {
  if (!siteId) throw Error("Site ID is required to fetch equipment");

  let query = supabase
    .from('compliance_items')
    .select("*")
    .eq('site_id', siteId)

  const { data, error, count } = await query;
  return { data, error, count };
}

export const fetchComplianceGroups = async (siteId: string, id: string) => {
  if (!siteId) throw Error("Site ID is required to fetch compliance groups");
  let query = supabase
    .from('compliance_item_group')
    .select('*, equipment:compliance_item_equipment_entry(*, ...equipment(site_id))')
    .eq('site_id', siteId)

  if (id) {
    query.eq('compliance_component_id', id)
  }

  const { data, error } = await query;
  return { data, error };
}

export const insertComplianceGroup = async (payload: CreateComplianceGroupPayload) => {
  let query = supabase
    .from('compliance_item_group')
    .upsert(payload)
    .select()
    .single()

  const { data, error } = await query;
  return { data, error };
}

export const deleteComplianceGroup = async (id: string) => {
  let query = supabase
    .from('compliance_item_group')
    .delete()
    .eq('id', id)
    .select()
    .single()

  const { data, error } = await query;
  return { data, error };
}

export type InsertComplianceEntryType = {
  compliance_item_group_id: string;
  equipment_id: string;
}

export const insertComplianceEntry = async (entries: InsertComplianceEntryType[]) => {
  let query = supabase
    .from('compliance_item_equipment_entry')
    .insert(entries)
    .select()

  const { data, error } = await query;
  return { data, error };
}

export type DeleteComplianceEntry = {
  compliance_item_group_id: string;
  equipment_ids: string[];
}

export const deleteComplianceEntriesByEquipment = async (entries: DeleteComplianceEntry) => {
  let query = supabase
    .from('compliance_item_equipment_entry')
    .delete()
    .eq('compliance_item_group_id', entries.compliance_item_group_id)
    .in('equipment_id', entries.equipment_ids)

  const { data, error } = await query;
  return { data, error };
}

export type ComplianceItemPayload = {
  id?: string
  site_id: string
  name: string
}

export const insertComplianceItem = async (siteId: string | undefined, payload: ComplianceItemPayload) => {
  if (!siteId) throw Error("Site ID is required to create compliance item");

  let query = supabase
    .from('compliance_items')
    .upsert(payload)
    .select()
    .single()

  const { data, error } = await query;
  return { data, error };
}

export const deleteComplianceItem = async (id: string) => {
  let query = supabase
    .from('compliance_items')
    .delete()
    .eq('id', id)
    .select()
    .single()

  const { data, error } = await query;
  return { data, error };
}

export const updateComplianceEntry = async (id: string, payload: { last_done: string }) => {
  console.log(id, payload)
  let query = supabase
    .from('compliance_item_equipment_entry')
    .update(payload)
    .eq('id', id)
    .select()
    .single()

  const { data, error } = await query;
  return { data, error };
}

export const fetchEquipmentCompliance = async (siteId: string | undefined) => {
  if (!siteId) throw Error("Site ID is required to fetch equipment");

  let query = supabase
    .from('equipment_with_smu')
    .select("*, compliance_item_equipment_entry(*, compliance_item_group(*))")
    .eq('organization_site_id', siteId)
    .order('classification', { ascending: false })

  const { data, error, count } = await query;
  return { data, error, count };
}
