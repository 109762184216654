import { SiteType } from "@/types/user";
import { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "./AuthProvider";
import { useLocalStorage } from "@/hooks/useLocalStorage";
import { useNavigate } from "react-router-dom";

export interface ProviderProps {
  children?: React.ReactNode;
}

interface SiteContextType {
  selectedSite: SiteType | undefined
  handleSiteChange?: (site: SiteType) => void
}

const SiteContext = createContext<SiteContextType>({
  selectedSite: undefined,
});

export const useSiteSelector = () => {
  return useContext(SiteContext);
};

const SiteSelectProvider = ({ children }: ProviderProps) => {
  const { user, appUser } = useAuth();
  const navigate = useNavigate();
  const [selectedSite, setSelectedSite] = useLocalStorage<SiteType | undefined>("selectedSite", undefined);

  useEffect(() => {
    if (!appUser) return;
    if (!selectedSite) {
      setSelectedSite(appUser.sites[0]);
    }
  }, [user, appUser, selectedSite, setSelectedSite]);

  const handleSiteChange = async (site: SiteType) => {
    navigate('/');
    setSelectedSite(site);
  };

  return (
    <SiteContext.Provider value={{ selectedSite, handleSiteChange }}>
      {children}
    </SiteContext.Provider>
  );
};

export default SiteSelectProvider;