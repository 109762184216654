import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import { useEffect, useState } from "react"
import { supabase } from "@/lib/supabaseClient"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"

interface InviteUserProps {
  onClose: () => void
  onFormSuccess: (values: z.infer<typeof formSchema>) => void
  organization_id: string
  site_id: string
}

export const formSchema = z.object({
  organization_id: z.string(),
  site_id: z.string(),
  role_id: z.string({
    required_error: 'Site ID is required'
  }),
  first_name: z.string({
    required_error: 'Asset Number is required'
  }),
  last_name: z.string({
    required_error: 'Model is required'
  }),
  email: z.string({
    required_error: 'Classification is required'
  })
});

interface Role {
  id: string;
  role: string;
}

async function fetchRoles(organization_id: string): Promise<Role[]> {
  try {
    const { data, error } = await supabase
      .from('roles')
      .select('id, role')
      .eq('organization_id', organization_id);

    if (error) throw error;
    return data || [];
  } catch (error) {
    console.error('Error fetching roles:', error);
    return [];
  }
}

export function InviteUserForm({ onClose, onFormSuccess, organization_id, site_id }: InviteUserProps) {
  const [roles, setRoles] = useState<Role[]>([]);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema)
  })

  useEffect(() => {
    async function loadRoles() {
      const fetchedRoles = await fetchRoles(organization_id);
      setRoles(fetchedRoles);
    }
    loadRoles();
  }, [organization_id])

  useEffect(() => {
    if (!form) return;
    form.reset({
      organization_id: organization_id,
      site_id: site_id,
    })
  }, [organization_id, site_id, form]);

  function onSubmit(values: z.infer<typeof formSchema>) {
    console.log(values);
    onFormSuccess(values);
  }

  return (
    <Form {...form}>
      <form className="space-y-2">
        <div className="flex gap-2 items-stretch">
          <FormField
            control={form.control}
            name="first_name"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>First Name</FormLabel>
                <FormControl>
                  <Input placeholder="First Name" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="last_name"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>Last Name</FormLabel>
                <FormControl>
                  <Input placeholder="Last Name" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem className="flex-1">
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input placeholder="Email" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="role_id"
          render={({ field }) => (
            <FormItem className="flex-1">
              <FormLabel>Role</FormLabel>
              <Select onValueChange={field.onChange} value={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select a Time" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {roles.map((role) => (<SelectItem key={role.id} value={role.id}>{role.role}</SelectItem>))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex">
          <Button type="button" onClick={onClose} variant="ghost">Cancel</Button>
          <Button type="submit" onClick={form.handleSubmit(onSubmit)} className="ml-auto">Submit</Button>
        </div>
      </form >
    </Form >
  )
}