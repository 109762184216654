import { useState } from "react";
import { z } from "zod";
import { supabase } from "@/lib/supabaseClient";
import { useAuth } from "@/components/AuthProvider";
import { OrganizationSite } from "../queries";
import { formSchema, InviteUserForm } from "./InviteUserForm";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"

interface InviteUserDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  site: OrganizationSite;
}

const InviteUserDialog = ({ open, setOpen, site }: InviteUserDialogProps) => {
  const { appUser } = useAuth();

  const onFormSuccess = async (values: z.infer<typeof formSchema>) => {
    if (!values.email) return;

    const { data, error } = await supabase
      .from('invites')
      .insert({
        email: values.email,
        first_name: values.first_name,
        last_name: values.last_name,
        role_id: values.role_id,
        site_id: site.id,
        org_id: site.organization_id,
        is_org_owner: false,
        invited_by: appUser?.id
      })
      .select()
      .single();

    if (error) throw error;

    const { error: eventError } = await supabase.functions.invoke('send-invite-email', {
      body: {
        email: data.email,
        to_name: `${data.first_name} ${data.last_name}`,
        from_name: `${appUser?.first_name} ${appUser?.last_name}`,
        org_name: appUser?.organization?.name || "",
        site_name: site.name,
        invite_link: `${new URL(window.location.href).origin}/signup?invite=${encodeURIComponent(data.id)}&email=${encodeURIComponent(data.email)}`,
      }
    });

    if (eventError) {
      alert('Error calling sendInviteEvent:' + eventError);
    }

    setOpen(false);
  }

  const closeForm = () => {
    setOpen(false);
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="max-w-xl">
        <DialogHeader>
          <DialogTitle>Invite User</DialogTitle>
        </DialogHeader>
        <InviteUserForm onClose={closeForm} onFormSuccess={onFormSuccess} organization_id={site.organization_id} site_id={site.id} />
      </DialogContent>
    </Dialog>
  );
}

export default InviteUserDialog;