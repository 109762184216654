import { ColumnDef } from "@tanstack/react-table"
import { Badge } from "@/components/ui/badge"

import { Pencil1Icon } from "@radix-ui/react-icons"

import { ArrowUpDown, DeleteIcon, MoreHorizontal, Pencil, PencilIcon, TrashIcon } from "lucide-react"

import { Button } from "@/components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import moment from "moment"
import { NavLink } from "react-router-dom"
import { Sites, WorkStatuses, Systems, DowntimeTypes } from "@/types/maintanceFields"
import { Machine } from "@/pages/machines/list/table/columns"
import { ReadMore } from "@/components/ReadMore"

export interface StatusObject {
  label: string;
  value: string;
}

export interface FilterConfig {
  column: string;
  label: string;
  options: StatusObject[];
}

export const statusBadeColourMapping = (status: string) => {
  switch (status) {
    case "Complete":
    case "Monitor":
      // return "green"
      return "bg-green-500"
    case "In Progress":
    case "Wait - Parts":
    case "Wait - Labour":
    case "Wait - Client":
    case "Wait - Scope":
      // return "blue"
      return "bg-blue-500"
    case "Next PM":
    case "New Line Started":
      // return "orange"
      return "bg-orange-500"
    default:
      return ""
  }
}

const getOptions = (values: string[]) => values.reduce((acc: StatusObject[], status: string) => {
  acc.push({ label: status, value: status });
  return acc;
}, []);

export const filterConfig: FilterConfig[] = [
  {
    column: 'site_id',
    label: 'Site ID',
    options: getOptions(Sites)
  },
  {
    column: 'work_status',
    label: 'Status',
    options: getOptions(WorkStatuses)
  },
  {
    column: 'system',
    label: 'Systems',
    options: getOptions(Systems)
  },
  {
    column: 'downtime_type',
    label: 'Downtime Type',
    options: getOptions(DowntimeTypes)
  },
]

export type MaintenanceLog = {
  id?: string
  machine_id?: string | null
  site_id: string
  work_order: number | string | null
  work_order_description?: string | null
  description: string | null
  system: string | null
  downtime_type: string | null
  work_status: string | null
  smu: string | number | null
  date_down: Date | string | null
  time_down: string | null
  date_up: Date | string | null
  time_up: string | null
}

export type NewMaintenanceLog = {
  id?: string
  equipment_id?: string
  site_id: string
  work_order: string | undefined
  work_order_description?: string
  description: string | null
  system_id: string
  downtime_type_id: string
  status_id: string
  smu: string | undefined
  date_down: string | null
  time_down: string | null
  date_up: string | null
  time_up: string | null
  equipment: Machine
  status: {
    id: string
    name: string
    site_id: string
  }
  type: {
    id: string
    name: string
    site_id: string
  }
  system: {
    id: string
    name: string
    site_id: string
  }
}

interface ColumnProps {
  onEdit: (value: NewMaintenanceLog) => void
  onDelete: (value: NewMaintenanceLog) => void
}

export const columns = ({ onEdit, onDelete }: ColumnProps): ColumnDef<NewMaintenanceLog>[] => [
  {
    accessorKey: "description",
    header: "Description",
    enableResizing: false,
    size: 500,
    cell: ({ row }) => {
      const description: string = row.getValue('description');
      return <ReadMore id={Math.random() + ''} text={description} amountOfWords={12} />
    },
  },
  {
    accessorKey: "id",
    header: "id",
  },
  {
    accessorKey: "equipment_id",
    header: "Equipment Id",
  },
  {
    accessorKey: "equipment.site_id",
    header: "Site ID",
    cell: ({ row }) => {
      const machineId = row.getValue('equipment_id');
      const siteId = row.original.equipment.site_id;
      if (machineId) {
        return (
          <NavLink to={`/equipment/${machineId}`} className="underline text-blue-700">
            {siteId as string}
          </NavLink >
        )
      }
      return siteId
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id))
    }
  },
  {
    accessorKey: "smu",
    header: "SMU",
  },
  {
    accessorKey: "work_order",
    header: "Work Order",
  },
  {
    accessorKey: "system.name",
    header: "System",
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id))
    }
  },
  {
    accessorKey: "type.name",
    header: "Downtime Type",
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id))
    }
  },
  {
    accessorKey: "status.name",
    header: "Status",
    cell: ({ row }) => {
      const status: string = row.original.status.name;
      // return <Badge variant={statusBadeColourMapping(status)}>{status}</Badge>
      const color = statusBadeColourMapping(status);
      return <Badge className={color} variant={color !== '' ? 'default' : 'outline'}>{status}</Badge>
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id))
    }
  },
  {
    accessorKey: "date_down",
    header: "Date Down",
    cell: ({ row }) => {
      const date: string = row.getValue('date_down');
      const time: string = row.getValue('time_down');
      const formattedDate = moment(date, 'YYYY-MM-DD').format("DD/MM/yyyy");
      const formattedTime = moment(time, 'HH:mm').format('hh:mm A');

      if (date) {
        return formattedDate + ' ' + formattedTime;
      }
      return 'No date set';
    }
  },
  {
    accessorKey: "time_down",
    header: "Time Down",
  },
  {
    accessorKey: "date_up",
    header: "Date Up",
    cell: ({ row }) => {
      const date: string = row.getValue('date_up');
      const time: string = row.getValue('time_up');
      const formattedDate = moment(date, 'YYYY-MM-DD').format("DD/MM/yyyy");
      const formattedTime = moment(time, 'HH:mm').format('hh:mm A');

      if (date) {
        return formattedDate + ' ' + formattedTime;
      }

      return 'No date set';
    }
  },
  {
    accessorKey: "time_up",
    header: "Time Up",
  },
  {
    header: "Downtime",
    cell: ({ row }) => {
      if (!row.getValue('date_down') || !row.getValue('time_down') || !row.getValue('date_up') || !row.getValue('time_up')) {
        return '-'
      }

      const dateDown = row.getValue('date_down');
      const timeDown = row.getValue('time_down');
      const dateUp = row.getValue('date_up');
      const timeUp = row.getValue('time_up');

      const dateTimeDown = moment(`${dateDown} ${timeDown}`, "YYYY-MM-DD HH:mm");
      const dateTimeUp = moment(`${dateUp} ${timeUp}`, "YYYY-MM-DD HH:mm");

      // Calculate the time difference in milliseconds
      const timeDiffInMilliseconds = dateTimeUp.diff(dateTimeDown);

      // Convert the time difference to hours
      const timeDiffInHours = moment.duration(timeDiffInMilliseconds).asHours();

      return timeDiffInHours.toFixed(1) + 'hrs';
    },
    size: 100,
  },
  {
    id: 'actions',
    cell: ({ row }) => {
      return (
        <div className="flex justify-end gap-1">
          <Button variant="outline" size="sm" className="h-7 gap-1" onClick={() => {
            onEdit(row.original)
          }}>
            <PencilIcon className="h-3.5 w-3.5" />
          </Button>
          <Button variant="outline" size="sm" className="h-7 gap-1" onClick={() => {
            onDelete(row.original)
          }}>
            <TrashIcon className="h-3.5 w-3.5" />
          </Button>
        </div>
      )
    }
  }
]
