import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList
} from "@/components/ui/navigation-menu"
import HeaderLink from "./HeaderLink";
import { Avatar, AvatarFallback } from "../ui/avatar";
import { NavLink } from "react-router-dom";
import { useAuth } from "../AuthProvider";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { Button } from "../ui/button";
import SiteSelector from "./SiteSelector";
import { Roles } from "@/types/user";

const Header = () => {
  const { signOut, appUser } = useAuth();

  if (!appUser) return null;

  return (
    <header className="flex flex-col gap-2 px-6 py-4 bg-card border">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-4">
          <NavLink to="/">
            <div className="w-auto h-7">
              <svg data-v-e1d325d1="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 593 593" className="flow-icon -rotate-90" height="100%"><path fill="currentColor" fillRule="evenodd" d="M347 0c6 0 12 5 12 12v134l94-95c5-5 13-5 17 0l72 72c4 4 5 12 0 16v1l-95 94h134c7 0 12 5 12 12v101c0 7-5 12-12 12H447l95 94c4 5 5 13 0 17l-72 72c-4 4-12 5-16 0h-1l-94-95v134c0 7-5 12-12 12H246c-7 0-12-5-12-12v-70c0-22 9-43 24-59l130-130c14-14 14-37 0-51L259 142a84 84 0 0 1-25-59V12c0-7 5-12 12-12zM138 52h1l219 219c14 14 14 37 0 51L139 542c-4 5-12 5-17 0l-71-70c-4-5-5-12 0-17l95-96H12c-7 0-12-5-12-12V246c0-7 5-12 12-12h134l-95-94c-4-5-4-12 0-17l71-71c4-5 12-5 16 0"></path></svg>
            </div>
          </NavLink>
          <div className="flex flex-row items-center gap-2">
            <p className="text-sm font-semibold">{appUser.organization.name}</p>
            <span className="text-gray-300">/</span>
            <SiteSelector userSites={appUser.sites} />
          </div>
        </div>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant="outline"
              size="icon"
              className="overflow-hidden rounded-full"
            >
              <Avatar className="h-8 w-8">
                {appUser.first_name && appUser.last_name && <AvatarFallback className="bg-primary text-sm text-primary-foreground">{appUser?.first_name[0]}{appUser?.last_name[0]}</AvatarFallback>}
              </Avatar>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>My Account</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem>
              <NavLink to="/settings" replace={false}>
                Profile Settings
              </NavLink>
            </DropdownMenuItem>
            {appUser.role === Roles.Owner &&
              <DropdownMenuItem>
                <NavLink to="/settings/organization" replace={false}>
                  Organization Settings
                </NavLink>
              </DropdownMenuItem>
            }
            <DropdownMenuSeparator />
            <DropdownMenuItem className="cursor-pointer" onClick={signOut}>Logout</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <NavigationMenu>
        <NavigationMenuList>
          <NavigationMenuItem className="flex gap-4">
            <HeaderLink to="/" name="Overview" />
            <HeaderLink to="/events" name="Site Events" />
            <HeaderLink to="/maintenance" name="Maintenance" />
            <HeaderLink to="/equipment" name="Equipment" />
            <HeaderLink to="/smu" name="SMU" />
            <HeaderLink to="/compliance" name="Compliance" />
            {(appUser.role === Roles.Owner || appUser.role === Roles.Admin) &&
              <HeaderLink to="/reports" name="Reporting" />
            }
            <HeaderLink to="/settings" name="Settings" />
          </NavigationMenuItem>
        </NavigationMenuList>
      </NavigationMenu>
    </header >
  )
}

export default Header;