import { useState, useEffect, useCallback } from 'react';
import { ComplianceItemGroup } from '../../types';
import { fetchComplianceGroups } from '../../queries';
import { handleComplianceGroupSubmission, handleComplianceGroupDeletion } from './groupHandlers';
import { z } from 'zod';
import { formSchema } from './GroupForm';
import { SiteType } from '@/types/user';

export const useComplianceGroups = (siteId: string | undefined, selectedItemId: string | undefined) => {
  const [groups, setGroups] = useState<ComplianceItemGroup[]>([]);

  const loadGroups = useCallback(async () => {
    if (!siteId || !selectedItemId) return;

    const { data, error } = await fetchComplianceGroups(siteId, selectedItemId);
    if (error) {
      alert(error.message);
    }
    if (data) {
      setGroups(data);
    }
  }, [siteId, selectedItemId]);

  useEffect(() => {
    loadGroups();
  }, [loadGroups]);

  const handleGroupSubmission = async (values: z.infer<typeof formSchema>, selectedSite: SiteType, selectedGroup: ComplianceItemGroup | undefined) => {
    if (!siteId || !selectedItemId) return false;

    try {
      await handleComplianceGroupSubmission(values, selectedSite, selectedItemId, selectedGroup);
      await loadGroups();  // Reload groups after successful submission
      return true;
    } catch (error) {
      alert((error as Error).message);
      return false;
    }
  };

  const handleGroupDeletion = async (groupId: string) => {
    try {
      await handleComplianceGroupDeletion(groupId);
      await loadGroups();  // Reload groups after successful deletion
      return true;
    } catch (error) {
      alert((error as Error).message);
      return false;
    }
  };

  return {
    groups,
    handleGroupSubmission,
    handleGroupDeletion
  };
};