import { UserType } from "@/types/user"
import { ColumnDef } from "@tanstack/react-table"

export const siteCardColumns: ColumnDef<UserType>[] = [
  {
    accessorKey: "name",
    header: "Name",
    cell: ({ row }) => { return (`${row.original.first_name} ${row.original.last_name}`) }
  },
  {
    accessorKey: "roles.role",
    header: "role",
  },
  {
    accessorKey: "email",
    header: "Email",
  }
]
