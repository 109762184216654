import moment from "moment";
import { calculateDifferences } from "../util";
import { NewMaintenanceLog } from "@/pages/maintenance/table/columns";
import { SiteEvent } from "@/pages/siteEvents/SiteEvents";
import { SmuTest } from "@/pages/machines/detail/EquipmentDetail";

/*
  Total downtime

  Calculates the total amount of time an asset is down
*/
const calculateTDT = (records: NewMaintenanceLog[] | SiteEvent[]): number => {
  let totalDownTime = 0;

  records.forEach(record => {
    const { date_down, time_down, date_up, time_up } = record;
    if (date_down && time_down && date_up && time_up) {
      const dateTimeDown = moment(`${date_down} ${time_down}`, "YYYY-MM-DD HH:mm");
      const dateTimeUp = moment(`${date_up} ${time_up}`, "YYYY-MM-DD HH:mm");

      const timeDiffInMilliseconds = dateTimeUp.diff(dateTimeDown);
      const timeDiffInHours = moment.duration(timeDiffInMilliseconds).asHours();

      totalDownTime += timeDiffInHours;
    }
  });

  return totalDownTime;
}

/*
  Total operational time

  Given a range of SMUs, Calculates the total amount of time an asset was operational
*/
const calculateTOT = (smu: SmuTest[]): number => {
  let totalOperationalHours = 0;

  smu.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  const operationalHours = calculateDifferences(smu);
  operationalHours.forEach(hours => {
    totalOperationalHours += hours.hours;
  })

  return totalOperationalHours;
}

/*
  Mean time to repair

  Calculates the average amount of time it takes to repair an asset
*/
const calculateMTTR = (maintenanceLogs: NewMaintenanceLog[]): number => {
  let totalDownTime = calculateTDT(maintenanceLogs);
  let totalMaintenanceEvents = maintenanceLogs.length;

  const meantimeToRepair = (totalDownTime / totalMaintenanceEvents) || 0;
  return meantimeToRepair;
}

/*
  Mean time between failure

  Calculates the average time between failures
*/
const calculateMTBF = (smu: SmuTest[], maintenanceLogs: NewMaintenanceLog[]): number => {
  const failureDowntimeTypes = ["Failure", "Breakdown"];
  const failureMaintenance = maintenanceLogs.filter(log => failureDowntimeTypes.includes(log.type?.name))
  let totalMaintenanceEvents = failureMaintenance.length;
  let totalOperationalHours = 0;

  if (totalMaintenanceEvents === 0) return 0;

  smu.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  const operationalHours = calculateDifferences(smu);
  operationalHours.forEach(hours => {
    totalOperationalHours += hours.hours;
  })

  const meantimeBetweenFailure = (totalOperationalHours / totalMaintenanceEvents) || 0;
  return meantimeBetweenFailure;
}

/*
  Planned vs Unplanned Maintenance

  Calculates the percentage of hours spent doing maintenance that was planned vs unplanned 
*/
const calculatePlannedVsUnplannedMaintenance = (maintenanceLogs: NewMaintenanceLog[]): number => {
  let totalPlannedHours = 0;
  let totalUnplannedHours = 0;
  const plannedDownTypes = ["Scheduled", "Preventative", "Servicing", "Opportune"];
  const plannedMaintenance = maintenanceLogs.filter(log => plannedDownTypes.includes(log.type?.name))

  totalPlannedHours = calculateTDT(plannedMaintenance);
  totalUnplannedHours = calculateTDT(maintenanceLogs);

  const plannedHoursPercentage = (totalPlannedHours / totalUnplannedHours) * 100 || 0;
  return plannedHoursPercentage;
}

export {
  calculateTDT,
  calculateTOT,
  calculateMTTR,
  calculateMTBF,
  calculatePlannedVsUnplannedMaintenance
}