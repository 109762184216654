import { NavLink } from "react-router-dom";

interface HeaderLinkProps {
  name: string
  to: string
}

const HeaderLink = ({ name, to }: HeaderLinkProps) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) => {
        return isActive ? 'text-foreground' : 'text-muted-foreground';
      }}
    >
      <p className="text-sm font-medium">{name}</p>
    </NavLink >
  )
}

export default HeaderLink;