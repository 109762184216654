import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { ScrollArea } from "@/components/ui/scroll-area"
import { Checkbox } from "@/components/ui/checkbox"
import { useEffect, useState } from "react"
import { Machine } from "../machines/list/table/columns"
import { useSiteSelector } from "@/components/SiteProvider"
import { Button } from "@/components/ui/button";
import { supabase } from "@/lib/supabaseClient";
import { OrganizationSite } from "../settings/queries";
import { fetchAllEquipment } from "../machines/queries";

const SmuConfiguration = ({ site }: { site?: OrganizationSite }) => {
  const { selectedSite } = useSiteSelector();
  const [allEquipment, setAllEquipment] = useState<Machine[]>();

  useEffect(() => {
    if (!selectedSite) return;

    const siteId = site ? site.id : selectedSite.id;
    const getEquipment = async () => {
      const { data, error } = await fetchAllEquipment(siteId);

      if (data) {
        setAllEquipment(data);
      }

      if (error) {
        alert(error.message);
      }
    }

    getEquipment();
  }, [selectedSite, site]);

  const handleOnChange = (equipment: Machine) => {
    setAllEquipment(allEquipment?.map(item => {
      if (item.id === equipment.id) {
        return { ...item, smu_enabled: !equipment.smu_enabled };
      } else {
        return item;
      }
    }));
  }

  const handleSave = async () => {
    const { data, error } = await supabase
      .from('equipment')
      .upsert(allEquipment)
      .select()

    if (data) {
      setAllEquipment(data);
    }
    if (error) {
      alert(error.message);
    }
  }

  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-full">
        <Card>
          <CardHeader>
            <CardTitle>Equipment</CardTitle>
            <CardDescription>Configuraitons what equipment captures smu</CardDescription>
          </CardHeader>
          <CardContent className="flex flex-col gap-8">
            <div>
              <ScrollArea className="rounded-sm border">
                <ul>
                  {allEquipment && allEquipment.map(equipment => (
                    <li key={equipment.id} className="border-b p-2 flex items-center text-sm gap-4 hover:bg-muted cursor-pointer" onClick={() => { handleOnChange(equipment) }}>
                      <Checkbox
                        id={equipment.id}
                        value={equipment.id}
                        checked={equipment.smu_enabled}
                      />
                      {equipment.site_id}
                    </li>
                  ))}
                </ul>
              </ScrollArea>
              <Button onClick={handleSave}>Submit</Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </div >
  )
}

export default SmuConfiguration