import { Button } from "@/components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { supabase } from "@/lib/supabaseClient"
import { zodResolver } from "@hookform/resolvers/zod"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"
import { ExclamationTriangleIcon } from "@radix-ui/react-icons"
import { Navigate, useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { useAuth } from "@/components/AuthProvider"

export const formSchema = z.object({
  email: z.string({
    required_error: "Email is required",
  }).email("Email is not valid"),
  password: z.string({
    required_error: "Password is required",
  }).min(6, "Password must be at least 6 characters"),
});

type Invite = {
  email: string
  first_name: string
  id: string
  invited_by: string
  is_org_owner: boolean
  last_name: string
  org_id: string
  role_id: string
  site_id: string
}

export function CreatePassword() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [isloading, setIsLoading] = useState<boolean>(false);
  const [loginErrors, setLoginErrors] = useState<string | undefined>(undefined);
  const [invite, setInvite] = useState<Invite>();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const inviteId = searchParams.get("invite");
  const email = searchParams.get("email");

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema)
  })

  useEffect(() => {
    console.log('Route changed:', location.pathname + location.search);
    console.log('Invite:', inviteId);
    console.log('Email:', email);
    form.setValue("email", email || "");
    console.log(inviteId)

    const fetchInvite = async () => {
      if (inviteId) {
        const { data, error } = await supabase
          .from('invites')
          .select('*')
          .eq('id', inviteId)
          .single();

        if (!data && error) {
          console.log('Invite:', data);
          console.error('Error fetching invite:', error);
          setLoginErrors('Invite not found');
        } else {
          setInvite(data);
        }
      }
    };

    fetchInvite();
  }, [location, inviteId, email, form]);

  if (user) {
    return <Navigate to='/' replace />;
  }

  const signUp = async (values: z.infer<typeof formSchema>) => {
    setIsLoading(true);

    const { data, error } = await supabase.auth.signUp({
      email: values.email,
      password: values.password,
      options: {
        data: {
          is_org_owner: invite?.is_org_owner,
          site_id: invite?.site_id,
          organization_id: invite?.org_id,
          first_name: invite?.first_name,
          last_name: invite?.last_name,
          role_id: invite?.role_id,
          invite_id: invite?.id,
        }
      }
    })

    setIsLoading(false);

    if (data) {
      navigate('/');
    }

    if (error) {
      console.log(error)
    }
  }

  return (
    <div className="w-screen flex flex-col items-center justify-center pt-64">
      {loginErrors && (
        <div className="w-60">
          <Alert className="mb-4">
            <ExclamationTriangleIcon className="w-4 h-4 mr-2" />
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>{loginErrors}</AlertDescription>
          </Alert>
        </div>
      )}
      {!loginErrors &&
        <Card className="mx-auto max-w-sm">
          <CardHeader>
            <CardTitle>Create Password</CardTitle>
            <CardDescription>
              Create a password to login to your account
            </CardDescription>
          </CardHeader>
          <CardContent>
            <Form {...form}>
              <form className="space-y-2">
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Email</FormLabel>
                      <FormControl>
                        <Input
                          type="text"
                          placeholder="Enter your email"
                          disabled
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Password</FormLabel>
                      <FormControl>
                        <Input
                          type="password"
                          placeholder="Enter your password"
                          autoComplete="new-password"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className="flex">
                  <Button type="submit" className="w-full" onClick={form.handleSubmit(signUp)}>{isloading ? "Creating..." : "Create Password"}</Button>
                </div>
              </form >
            </Form >
          </CardContent>
        </Card>
      }
    </div>
  )
}
