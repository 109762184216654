import React, { ReactNode, createContext, useContext, useState } from "react";
import ConfirmDialog from "./ConfirmDialog";

interface ProviderProps {
  children?: ReactNode;
}

type DialogProps = {
  title: string,
  message: string,
  actionCallback: (value: boolean) => void
}

interface ContextType {
  openDialog: ({ title, message, actionCallback }: DialogProps) => void
}

const ConfirmationDialogContext = createContext<ContextType>({ openDialog: () => { } });

const ConfirmationDialogProvider = ({ children }: ProviderProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogConfig, setDialogConfig] = useState<DialogProps | undefined>();

  const openDialog = ({ title, message, actionCallback }: DialogProps) => {
    setDialogOpen(true);
    setDialogConfig({ title, message, actionCallback });
  };

  const resetDialog = () => {
    setDialogOpen(false);
    setDialogConfig(undefined);
  };

  const onConfirm = () => {
    resetDialog();
    dialogConfig?.actionCallback(true);
  };

  const onDismiss = () => {
    resetDialog();
    dialogConfig?.actionCallback(false);
  };

  return (
    <ConfirmationDialogContext.Provider value={{ openDialog }}>
      <ConfirmDialog
        open={dialogOpen}
        title={dialogConfig?.title}
        message={dialogConfig?.message}
        onConfirm={onConfirm}
        onClose={onDismiss}
      />
      {children}
    </ConfirmationDialogContext.Provider>
  );
};

const useConfirmationDialog = () => {
  const { openDialog } = useContext(ConfirmationDialogContext);

  const getConfirmation = ({ title, message }: { title: string, message: string }) =>
    new Promise((res) => {
      openDialog({ actionCallback: res, title, message });
    });

  return { getConfirmation };
};

export { ConfirmationDialogProvider, useConfirmationDialog };
