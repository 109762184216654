import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Form, FormField, FormItem, FormLabel, FormControl, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { supabase } from '@/lib/supabaseClient';
import { useAuth } from '@/components/AuthProvider';
import { OrganizationSite } from '../queries';
import { useEffect } from 'react';

const schema = z.object({
  siteName: z.string().min(1, 'Site name is required'),
});

type FormData = z.infer<typeof schema>;

const SiteNameInput = ({ site }: { site?: OrganizationSite }) => {
  const { appUser, getAppUser } = useAuth();
  const form = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      siteName: site?.name || '',
    },
  });

  useEffect(() => {
    if (site) {
      form.reset({ siteName: site.name });
    }
  }, [site, form]);

  const onSubmit = async (data: FormData) => {
    try {
      const { data: upsertedSite, error } = await supabase
        .from('sites')
        .upsert({
          id: site?.id,
          name: data.siteName,
          organization_id: appUser?.organization_id
        })
        .select()
        .single();

      if (error) throw error;

      if (appUser && getAppUser) {
        getAppUser(appUser.id);
      }

      form.reset({ siteName: upsertedSite.name });
    } catch (error) {
      console.error('Error upserting site:', error);
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="siteName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Site Name</FormLabel>
              <FormControl>
                <Input placeholder="Enter site name" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button type="submit">{site ? 'Update' : 'Submit'}</Button>
      </form>
    </Form>
  )
}

export default SiteNameInput;
