import { ColumnDef } from '@tanstack/react-table'
import { Button } from '@/components/ui/button';
import { EllipsisIcon } from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { ComplianceItemGroup, ComplianceItemEquipmentEntry } from '../../types';

interface GroupColumnProps {
  onEdit: (value: ComplianceItemGroup) => void
  onDelete: (value: ComplianceItemGroup) => void
}

export const groupTableColumns = ({ onEdit, onDelete }: GroupColumnProps): ColumnDef<ComplianceItemGroup>[] => [
  {
    accessorKey: "name",
    header: "name",
  },
  {
    accessorKey: "interval",
    header: "Interval",
    cell: ({ row }) => {
      const hours = row.getValue('interval') as number;
      const type = row.getValue('interval_type') as string;
      return <p>Every {hours} {type}s</p>
    },
  },
  {
    accessorKey: "interval_type",
    header: "Interval Type",
  },
  {
    accessorKey: "alert_low",
    header: "Alert Low",
    cell: ({ row }) => {
      const intervalType = row.getValue("interval_type");
      const hours = row.getValue('alert_low') as number;
      return <Badge className="bg-yellow-500">{hours} {intervalType === "month" ? "Days" : "Hours"}</Badge>
    },
  },
  {
    accessorKey: "alert_medium",
    header: "Alert Medium",
    cell: ({ row }) => {
      const intervalType = row.getValue("interval_type");
      const hours = row.getValue('alert_medium') as number;
      return <Badge className="bg-orange-500">{hours} {intervalType === "month" ? "Days" : "Hours"}</Badge>
    },
  },
  {
    accessorKey: "alert_high",
    header: "Alert High",
    cell: ({ row }) => {
      const intervalType = row.getValue("interval_type");
      const hours = row.getValue('alert_high') as number;
      return <Badge className="bg-red-500">{hours} {intervalType === "month" ? "Days" : "Hours"}</Badge>
    },
  },
  {
    accessorKey: "equipment",
    header: "Equipment",
    cell: ({ row }) => {
      const equipment = row.getValue('equipment') as ComplianceItemEquipmentEntry[];
      const tagList = equipment.map(e => (<Badge key={e.id} variant="outline">{e.site_id}</Badge>))
      return <div className="flex flex-wrap gap-2">{tagList}</div>
    },
    size: 400,
  },
  {
    id: 'actions',
    cell: ({ row }) => {
      return (
        <DropdownMenu>
          <DropdownMenuTrigger>
            <Button size="icon" variant="ghost">
              <EllipsisIcon className="w-4 h-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem onClick={() => onEdit(row.original)}>Edit</DropdownMenuItem>
            <DropdownMenuItem onClick={() => onDelete(row.original)}>Delete</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )
    }
  }
];