import { z } from "zod";
import { formSchema } from "./GroupForm";
import {
  CreateComplianceGroupPayload,
  deleteComplianceEntriesByEquipment,
  deleteComplianceGroup,
  insertComplianceEntry,
  InsertComplianceEntryType,
  insertComplianceGroup,
} from "../../queries";
import { SiteType } from "@/types/user";
import { ComplianceItemGroup } from "../../types";

export const handleComplianceGroupSubmission = async (
  values: z.infer<typeof formSchema>,
  selectedSite: SiteType,
  complianceItemId: string,
  selectedGroup?: ComplianceItemGroup
) => {
  const payload: CreateComplianceGroupPayload = {
    site_id: selectedSite.id as string,
    compliance_component_id: complianceItemId,
    name: values.name,
    interval_type: values.interval_type,
    interval: values.interval,
    alert_low: values.alert_low,
    alert_medium: values.alert_medium,
    alert_high: values.alert_high,
  };

  if (values.id) {
    payload.id = values.id;
  }

  const { data: newGroup, error: groupError } = await insertComplianceGroup(payload);

  if (groupError) {
    throw new Error(groupError.message);
  }

  if (newGroup) {
    await handleEquipmentChanges(newGroup, values, selectedGroup);
  }
};

const handleEquipmentChanges = async (
  newGroup: { id: string; name: string },
  values: z.infer<typeof formSchema>,
  selectedItem?: ComplianceItemGroup
) => {
  const entriesToCreate = selectedItem
    ? values.equipment.filter((id) => !selectedItem.equipment.some((eq) => eq.equipment_id === id))
    : values.equipment;

  const entriesToDelete = selectedItem
    ? selectedItem.equipment
        .map((eq) => eq.equipment_id)
        .filter((id) => !values.equipment.includes(id))
    : [];

  if (entriesToCreate.length > 0) {
    await createComplianceEntries(newGroup.id, entriesToCreate);
  }

  if (entriesToDelete.length > 0) {
    await deleteComplianceEntries(newGroup.id, entriesToDelete);
  }
};

const createComplianceEntries = async (groupId: string, equipmentIds: string[]) => {
  const entriesPayload: InsertComplianceEntryType[] = equipmentIds.map((machine) => ({
    compliance_item_group_id: groupId,
    equipment_id: machine,
  }));

  const { error } = await insertComplianceEntry(entriesPayload);

  if (error) {
    throw new Error(error.message);
  }
};

const deleteComplianceEntries = async (groupId: string, equipmentIds: string[]) => {
  const entriesPayload = {
    compliance_item_group_id: groupId,
    equipment_ids: equipmentIds,
  };

  const { error } = await deleteComplianceEntriesByEquipment(entriesPayload);

  if (error) {
    throw new Error(error.message);
  }
};

export const handleComplianceGroupDeletion = async (groupId: string) => {
  const { error } = await deleteComplianceGroup(groupId);

  if (error) {
    throw new Error(error.message);
  }
};