const Sites = [
  'DZ002',
  'DZ017',
  'DZ021',
  'EX310',
  'GR1607',
  'RD565',
  'RD566',
  'RD567',
  'RD568',
  'RD570',
  'SS434',
  'ST518',
  'WT607',
  'Franna',
  'LDW644',
  'CMS84',
  'CMS81',
  'CMS75',
  'CMS65',
  'CMS51',
  'BMA Asset'
]

const Systems = [
  'A / C',
  'Access System',
  'Brakes',
  'BreatheSafe',
  'Cab',
  'Cooling',
  'Drivetrain',
  'Electrical',
  'Engine',
  'Fire Suppression',
  'G,E,T',
  'Grease System',
  'Hydraulic',
  'PM / Service',
  'Seat',
  'Service Module',
  'Site Compliance',
  'Spray System',
  'Steering',
  'Tyres',
  'Transmission',
  'Undercarriage',
]

const WorkStatuses = [
  'Complete',
  'In Progress',
  'Monitor',
  'Next PM',
  'New Line Started',
  'Wait - Parts',
  'Wait - Labour',
  'Wait - Client',
  'Wait - Scope',
]

const DowntimeTypes = [
  'Scheduled',
  'Preventative',
  'Failure',
  'Acc Damage',
  'Servicing',
  'Opportune',
  'Operator Error',
  'Non Contract',
  'Breakdown',
  'Client',
  'N/A',
]

const DownTimeGroupMappings: {[key: string]: string} = {
  "Scheduled": "Repairs/Breakdown/Service",
  "Preventative": "Repairs/Breakdown/Service",
  "Failure": "Repairs/Breakdown/Service",
  "Acc Damage": "Accident Damage",
  "Servicing": "Repairs/Breakdown/Service",
  "Opportune": "Opportune Maintenance",
  "Operator Error": "Opportune Maintenance",
  "Non Contract": "Opportune Maintenance",
  "Breakdown": "Repairs/Breakdown/Service",
  "Wet Weather": "Wet Weather & No Production",
  "Prod S/ Down": "Wet Weather & No Production",
  "No Operators": "Wet Weather & No Production",
  "Client": "Maint Delays Due to Client Process",
  "Site Shutdown": "Site Shutdown Due to Emergency/ERT",
  "N/A": "N/A",
}

const ExcludedDowntimeCategories = [
  "Accident Damage",
  "Opportune Maintenance",
  "Wet Weather & No Production",
  "Maint Delays Due to Client Process",
  "Site Shutdown Due to Emergency/ERT",
]

export {
  Sites,
  Systems,
  WorkStatuses,
  DowntimeTypes,
  DownTimeGroupMappings,
  ExcludedDowntimeCategories
};