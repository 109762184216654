import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { CalendarIcon } from "@radix-ui/react-icons"
import { Calendar } from "@/components/ui/calendar"
import { cn } from "@/lib/utils";
import moment from "moment";
import { ComplianceEntryForm } from "../types";

interface ComplianceItemFormProps {
  onClose: () => void
  onFormSuccess: (values: z.infer<typeof formSchema>) => void
  selectedEntry: ComplianceEntryForm
}

export const formSchema = z.object({
  id: z.string({
    required_error: 'id is required'
  }),
  component: z.string({
    required_error: 'component is required'
  }),
  equipment: z.string({
    required_error: 'equipment is required'
  }),
  group: z.string({
    required_error: 'group is required'
  }),
  lastDone: z.string({
    required_error: 'lastDone is required'
  })
});

const ComplianceItemEntryForm = ({ onClose, onFormSuccess, selectedEntry }: ComplianceItemFormProps) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      id: selectedEntry.id,
      component: selectedEntry.component,
      equipment: selectedEntry.equipment,
      group: selectedEntry.group,
      lastDone: selectedEntry.lastDone
    },
  })

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    onFormSuccess(values)
  }

  return (
    <Form {...form}>
      <form className="space-y-2">
        <FormField
          control={form.control}
          name="component"
          render={({ field }) => (
            <FormItem className="flex-1">
              <FormLabel>Component</FormLabel>
              <Input disabled {...field} />
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="equipment"
          render={({ field }) => (
            <FormItem className="flex-1">
              <FormLabel>Equipment</FormLabel>
              <Input disabled {...field} />
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="group"
          render={({ field }) => (
            <FormItem className="flex-1">
              <FormLabel>Group</FormLabel>
              <Input disabled {...field} />
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="lastDone"
          render={({ field }) => (
            <FormItem className="flex flex-col flex-1">
              <FormLabel>Last Done</FormLabel>
              {selectedEntry.intervalType === "hour" && <Input {...field} />}
              {selectedEntry.intervalType === "month" && (
                <Popover>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <Button
                        variant={"outline"}
                        className={cn(
                          "w-auto pl-3 text-left font-normal",
                          !field.value && "text-muted-foreground"
                        )}
                      >
                        {field.value ? (
                          moment(field.value).format("DD/MM/YY")
                        ) : (
                          <span>Pick a date</span>
                        )}
                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      mode="single"
                      selected={moment(field.value, 'YYYY-MM-DD').toDate()}
                      onSelect={(date) => field.onChange(moment(date).format("YYYY-MM-DD"))}
                      disabled={(date) =>
                        date > new Date() || date < new Date("1900-01-01")
                      }
                      initialFocus
                    />
                  </PopoverContent>
                </Popover>
              )}
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex">
          <Button type="button" onClick={onClose} variant="ghost">Cancel</Button>
          <Button type="submit" onClick={form.handleSubmit(onSubmit)} className="ml-auto">Submit</Button>
        </div>
      </form>
    </Form>
  )
}

export default ComplianceItemEntryForm;