import { Table } from "@tanstack/react-table"
import { Filter } from "./Filter"
import { FilterConfig } from "../../pages/maintenance/table/columns";

interface DataTableToolbarProps<TData> {
  table: Table<TData>
  filterConfig?: FilterConfig[]
}

const Filters = <TData,>({ table, filterConfig }: DataTableToolbarProps<TData>) => {
  return (
    <div className="flex gap-2 items-center">
      {filterConfig && filterConfig.map((config: FilterConfig) => (
        table.getColumn(config.column) && (
          <Filter
            column={table.getColumn(config.column)}
            title={config.label}
            options={config.options}
          />
        )
      ))}
    </div>
  )
}

export default Filters;