import { supabase } from "@/lib/supabaseClient";
import { PaginationState } from "@tanstack/react-table";
import { uniq } from "lodash";

const fetchAllEquipment = async (siteId: string | undefined, pagination?: PaginationState, modelFilters?: string[], classificationFilters?: string[]) => {
  if (!siteId) throw Error("Site ID is required to fetch equipment");

  let query = supabase
    .from('equipment')
    .select("*", { count: 'exact' })
    .eq('organization_site_id', siteId)
    .order('classification', { ascending: true })

  if (pagination) {
    const startRange = pagination.pageIndex * (pagination.pageSize - 1);
    const endRange = startRange + (pagination.pageSize - 1);
    query = query.range(startRange, endRange);
  }

  if (modelFilters && modelFilters.length > 0) {
    query = query.in('model', modelFilters);
  }

  if (classificationFilters && classificationFilters.length > 0) {
    query = query.in('classification', classificationFilters);
  }

  const { data, error, count } = await query;
  return { data, error, count };
}

const fetchEquipmentWithLogs = async (id: string) => {
  let query = supabase
    .from('equipment_with_smu')
    .select("*, maintenance_logs(*, status:maintenance_statuses(*), system:maintenance_systems(*), type:maintenance_types(*)), all_smu:smu(*)")
    .eq('id', id)
    .single()

  const { data, error } = await query;
  return { data, error };
}

const fetchEquipmentModels = async (siteId: string | undefined) => {
  if (!siteId) throw Error("Site ID is required to fetch equipment models");

  let query = supabase
    .from('equipment')
    .select('model')
    .eq('organization_site_id', siteId)
    .order('model', { ascending: true })

  const { data, error } = await query;
  
  const uniqueModels = uniq(data?.map(item => item.model).filter(item => item !== null && item !== ''));
  
  return { data: uniqueModels, error };
}

const fetchEquipmentClassifications = async (siteId: string | undefined) => {
  if (!siteId) throw Error("Site ID is required to fetch equipment models");

  let query = supabase
    .from('equipment')
    .select('classification')
    .eq('organization_site_id', siteId)
    .order('model', { ascending: true })

  const { data, error } = await query;
  
  const uniqueModels = uniq(data?.map(item => item.classification).filter(item => item !== null && item !== ''));

  return { data: uniqueModels, error };
}

export { fetchAllEquipment, fetchEquipmentWithLogs, fetchEquipmentModels, fetchEquipmentClassifications };