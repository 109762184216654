import { type ClassValue, clsx } from "clsx"
import moment from "moment";
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const generateTimeValues = () => {
  let times = [];
  let currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0); // Set time to 12:00am
  
  // Loop from 12:00am to 11:55pm with 5-minute increments
  for (let i = 0; i < 288; i++) {
      times.push(currentDate.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})); // Push formatted time to array
      currentDate.setMinutes(currentDate.getMinutes() + 5); // Add 5 minutes
  }
  
  return times;
}

export const closestTimeToNow = (timeValues: string[]) => {
  const now = moment(); // Current time
  let closestTime = moment(timeValues[0], 'hh:mm A'); // Initialize closestTime with the first time in the list
  let closestIndex = 0; // Initialize closestIndex with the index of the first time in the list

  // Iterate through the list of times and find the closest one to now
  timeValues.forEach((time, index) => {
      const currentTime = moment(time, 'hh:mm A');
      if (Math.abs(now.diff(currentTime)) < Math.abs(now.diff(closestTime))) {
          closestTime = currentTime;
          closestIndex = index;
      }
  });

  return timeValues[closestIndex];
}

export const WeekSelect = [
  "1/7/23",
  "8/7/23",
  "15/7/23",
  "22/7/23",
  "29/7/23",
  "5/8/23",
  "12/8/23",
  "19/8/23",
  "26/8/23",
  "2/9/23",
  "9/9/23",
  "16/9/23",
  "23/9/23",
  "30/9/23",
  "7/10/23",
  "14/10/23",
  "21/10/23",
  "28/10/23",
  "4/11/23",
  "11/11/23",
  "18/11/23",
  "25/11/23",
  "2/12/23",
  "9/12/23",
  "16/12/23",
  "23/12/23",
  "30/12/23",
  "6/1/24",
  "13/1/24",
  "20/1/24",
  "27/1/24",
  "3/2/24",
  "10/2/24",
  "17/2/24",
  "24/2/24",
  "2/3/24",
  "9/3/24",
  "16/3/24",
  "23/3/24",
  "30/3/24",
  "6/4/24",
  "13/4/24",
  "20/4/24",
  "27/4/24",
  "4/5/24",
  "11/5/24",
  "18/5/24",
  "25/5/24",
  "1/6/24",
  "8/6/24",
  "15/6/24",
  "22/6/24",
  "29/6/24",
  "6/7/24",
  "13/7/24",
  "20/7/24",
  "27/7/24",
  "3/8/24",
  "10/8/24",
  "17/8/24",
  "24/8/24",
  "31/8/24",
  "7/9/24",
  "14/9/24",
  "21/9/24",
  "28/9/24",
  "5/10/24",
  "12/10/24",
  "19/10/24",
  "26/10/24",
  "2/11/24",
  "9/11/24",
  "16/11/24",
  "23/11/24",
  "30/11/24",
  "7/12/24",
  "14/12/24",
  "21/12/24",
  "28/12/24",
]

export const TimeSelectOptions = [
  "12:00 AM",
  "12:05 AM",
  "12:10 AM",
  "12:15 AM",
  "12:20 AM",
  "12:25 AM",
  "12:30 AM",
  "12:35 AM",
  "12:40 AM",
  "12:45 AM",
  "12:50 AM",
  "12:55 AM",
  "01:00 AM",
  "01:05 AM",
  "01:10 AM",
  "01:15 AM",
  "01:20 AM",
  "01:25 AM",
  "01:30 AM",
  "01:35 AM",
  "01:40 AM",
  "01:45 AM",
  "01:50 AM",
  "01:55 AM",
  "02:00 AM",
  "02:05 AM",
  "02:10 AM",
  "02:15 AM",
  "02:20 AM",
  "02:25 AM",
  "02:30 AM",
  "02:35 AM",
  "02:40 AM",
  "02:45 AM",
  "02:50 AM",
  "02:55 AM",
  "03:00 AM",
  "03:05 AM",
  "03:10 AM",
  "03:15 AM",
  "03:20 AM",
  "03:25 AM",
  "03:30 AM",
  "03:35 AM",
  "03:40 AM",
  "03:45 AM",
  "03:50 AM",
  "03:55 AM",
  "04:00 AM",
  "04:05 AM",
  "04:10 AM",
  "04:15 AM",
  "04:20 AM",
  "04:25 AM",
  "04:30 AM",
  "04:35 AM",
  "04:40 AM",
  "04:45 AM",
  "04:50 AM",
  "04:55 AM",
  "05:00 AM",
  "05:05 AM",
  "05:10 AM",
  "05:15 AM",
  "05:20 AM",
  "05:25 AM",
  "05:30 AM",
  "05:35 AM",
  "05:40 AM",
  "05:45 AM",
  "05:50 AM",
  "05:55 AM",
  "06:00 AM",
  "06:05 AM",
  "06:10 AM",
  "06:15 AM",
  "06:20 AM",
  "06:25 AM",
  "06:30 AM",
  "06:35 AM",
  "06:40 AM",
  "06:45 AM",
  "06:50 AM",
  "06:55 AM",
  "07:00 AM",
  "07:05 AM",
  "07:10 AM",
  "07:15 AM",
  "07:20 AM",
  "07:25 AM",
  "07:30 AM",
  "07:35 AM",
  "07:40 AM",
  "07:45 AM",
  "07:50 AM",
  "07:55 AM",
  "08:00 AM",
  "08:05 AM",
  "08:10 AM",
  "08:15 AM",
  "08:20 AM",
  "08:25 AM",
  "08:30 AM",
  "08:35 AM",
  "08:40 AM",
  "08:45 AM",
  "08:50 AM",
  "08:55 AM",
  "09:00 AM",
  "09:05 AM",
  "09:10 AM",
  "09:15 AM",
  "09:20 AM",
  "09:25 AM",
  "09:30 AM",
  "09:35 AM",
  "09:40 AM",
  "09:45 AM",
  "09:50 AM",
  "09:55 AM",
  "10:00 AM",
  "10:05 AM",
  "10:10 AM",
  "10:15 AM",
  "10:20 AM",
  "10:25 AM",
  "10:30 AM",
  "10:35 AM",
  "10:40 AM",
  "10:45 AM",
  "10:50 AM",
  "10:55 AM",
  "11:00 AM",
  "11:05 AM",
  "11:10 AM",
  "11:15 AM",
  "11:20 AM",
  "11:25 AM",
  "11:30 AM",
  "11:35 AM",
  "11:40 AM",
  "11:45 AM",
  "11:50 AM",
  "11:55 AM",
  "12:00 PM",
  "12:05 PM",
  "12:10 PM",
  "12:15 PM",
  "12:20 PM",
  "12:25 PM",
  "12:30 PM",
  "12:35 PM",
  "12:40 PM",
  "12:45 PM",
  "12:50 PM",
  "12:55 PM",
  "01:00 PM",
  "01:05 PM",
  "01:10 PM",
  "01:15 PM",
  "01:20 PM",
  "01:25 PM",
  "01:30 PM",
  "01:35 PM",
  "01:40 PM",
  "01:45 PM",
  "01:50 PM",
  "01:55 PM",
  "02:00 PM",
  "02:05 PM",
  "02:10 PM",
  "02:15 PM",
  "02:20 PM",
  "02:25 PM",
  "02:30 PM",
  "02:35 PM",
  "02:40 PM",
  "02:45 PM",
  "02:50 PM",
  "02:55 PM",
  "03:00 PM",
  "03:05 PM",
  "03:10 PM",
  "03:15 PM",
  "03:20 PM",
  "03:25 PM",
  "03:30 PM",
  "03:35 PM",
  "03:40 PM",
  "03:45 PM",
  "03:50 PM",
  "03:55 PM",
  "04:00 PM",
  "04:05 PM",
  "04:10 PM",
  "04:15 PM",
  "04:20 PM",
  "04:25 PM",
  "04:30 PM",
  "04:35 PM",
  "04:40 PM",
  "04:45 PM",
  "04:50 PM",
  "04:55 PM",
  "05:00 PM",
  "05:05 PM",
  "05:10 PM",
  "05:15 PM",
  "05:20 PM",
  "05:25 PM",
  "05:30 PM",
  "05:35 PM",
  "05:40 PM",
  "05:45 PM",
  "05:50 PM",
  "05:55 PM",
  "06:00 PM",
  "06:05 PM",
  "06:10 PM",
  "06:15 PM",
  "06:20 PM",
  "06:25 PM",
  "06:30 PM",
  "06:35 PM",
  "06:40 PM",
  "06:45 PM",
  "06:50 PM",
  "06:55 PM",
  "07:00 PM",
  "07:05 PM",
  "07:10 PM",
  "07:15 PM",
  "07:20 PM",
  "07:25 PM",
  "07:30 PM",
  "07:35 PM",
  "07:40 PM",
  "07:45 PM",
  "07:50 PM",
  "07:55 PM",
  "08:00 PM",
  "08:05 PM",
  "08:10 PM",
  "08:15 PM",
  "08:20 PM",
  "08:25 PM",
  "08:30 PM",
  "08:35 PM",
  "08:40 PM",
  "08:45 PM",
  "08:50 PM",
  "08:55 PM",
  "09:00 PM",
  "09:05 PM",
  "09:10 PM",
  "09:15 PM",
  "09:20 PM",
  "09:25 PM",
  "09:30 PM",
  "09:35 PM",
  "09:40 PM",
  "09:45 PM",
  "09:50 PM",
  "09:55 PM",
  "10:00 PM",
  "10:05 PM",
  "10:10 PM",
  "10:15 PM",
  "10:20 PM",
  "10:25 PM",
  "10:30 PM",
  "10:35 PM",
  "10:40 PM",
  "10:45 PM",
  "10:50 PM",
  "10:55 PM",
  "11:00 PM",
  "11:05 PM",
  "11:10 PM",
  "11:15 PM",
  "11:20 PM",
  "11:25 PM",
  "11:30 PM",
  "11:35 PM",
  "11:40 PM",
  "11:45 PM",
  "11:50 PM",
  "11:55 PM"
]
