import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  TooltipProps,
  Rectangle,
} from 'recharts';
import {
  ValueType,
  NameType,
} from 'recharts/types/component/DefaultTooltipContent';
import { AnalyticsObj } from './Overview';

interface ReportChartProps {
  data: AnalyticsObj[]
}

const CustomTooltip = ({
  active,
  payload,
  label,
}: TooltipProps<ValueType, NameType>) => {
  if (active) {
    return (
      <div className="rounded-md border bg-card text-card-foreground shadow-md p-4">
        <p className="font-medium">{label}</p>
        <ul className="flex flex-col gap-1">
          {payload?.map(item => (
            <li style={{ color: item.color }}>{item.name}: {item.value}</li>
          ))}
        </ul>
      </div>
    );
  }

  return null;
};

const OverviewBarChart = ({ data }: ReportChartProps) => {
  return (
    <ResponsiveContainer width='100%' height={500}>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" type="category" />
        <YAxis />
        <Tooltip isAnimationActive={false} content={<CustomTooltip />} />
        <Legend />
        <Bar dataKey="value" fill="#8884d8" activeBar={<Rectangle fill="pink" stroke="blue" />} isAnimationActive={false} />
      </BarChart>
    </ResponsiveContainer>
  )
}

export default OverviewBarChart;