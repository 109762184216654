import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import SiteEvents from "./SiteEvents";
import Analytics from "./Analytics";
import { useLocation, useNavigate } from "react-router-dom";

const SiteEventsTab = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentTab = location.pathname.endsWith("/insights") ? "insights" : "events";

  const handleTabChange = (value: string) => {
    navigate(value === "insights" ? "/events/insights" : "/events");
  };

  return (
    <div>
      <Tabs value={currentTab} onValueChange={handleTabChange}>
        <TabsList>
          <TabsTrigger value="events">Site Events</TabsTrigger>
          <TabsTrigger value="insights">Insights</TabsTrigger>
        </TabsList>
        <div>
          <TabsContent value="events">
            <SiteEvents />
          </TabsContent>
          <TabsContent value="insights">
            <Analytics />
          </TabsContent>
        </div>
      </Tabs>
    </div>
  )
}

export default SiteEventsTab;