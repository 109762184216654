import { SiteType } from "@/types/user";
import React, { useState, useEffect, Dispatch, SetStateAction } from "react";

type KeyType = string;
type ValueType = string | undefined | SiteType; // Adjust the type according to your use case

function getStorageValue(key: KeyType, defaultValue: ValueType): ValueType {
  const saved = localStorage.getItem(key);
  if (saved === null) return defaultValue;
  try {
    return JSON.parse(saved);
  } catch {
    return saved;
  }
}

export const useLocalStorage = <T extends ValueType>(key: KeyType, defaultValue: T): [T, Dispatch<SetStateAction<T>>] => {
  const [value, setValue] = useState<T>(() => {
    return getStorageValue(key, defaultValue) as T;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};