import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { fetchSites, OrganizationSite } from "../queries";
import { useAuth } from "@/components/AuthProvider";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import SiteCard from "./SiteCard";
import SystemsConfig from "@/pages/maintenance/config/SystemsConfig";
import StatusConfig from "@/pages/maintenance/config/StatusConfig";
import TypeConfig from "@/pages/maintenance/config/TypeConfig";
import SiteTypesConfig from "@/pages/siteEvents/config/SiteTypesConfig";
import SiteStatusConfig from "@/pages/siteEvents/config/SiteStatusConfig";
import SmuConfiguration from "@/pages/smu/SmuConfiguration";

const SiteContainer = () => {
  const { id } = useParams();
  const { appUser } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [site, setSite] = useState<OrganizationSite>();

  useEffect(() => {
    if (!appUser) return;

    const getSite = async () => {
      const { data, error } = await fetchSites(appUser.organization_id, id);
      if (error) {
        alert(error.message);
      }
      if (data) {
        setSite(data[0]);
      }
    };

    getSite();
  }, [appUser, id]);

  const getCurrentTab = (pathname: string) => {
    if (pathname.endsWith('/maintenance')) return 'maintenance';
    if (pathname.endsWith('/events')) return 'events';
    if (pathname.endsWith('/smu')) return 'smu';
    return 'general';
  };

  const currentTab = getCurrentTab(location.pathname);

  const handleTabChange = (value: string) => {
    const baseUrl = `/settings/sites/${id}`;
    switch (value) {
      case 'maintenance':
      case 'events':
      case 'smu':
        navigate(`${baseUrl}/${value}`);
        break;
      default:
        navigate(baseUrl);
    }
  };

  if (!site) return <p>Loading...</p>;

  return (
    <div>
      <Tabs value={currentTab} onValueChange={handleTabChange}>
        <TabsList>
          <TabsTrigger value="general">General</TabsTrigger>
          <TabsTrigger value="maintenance">Maintenance</TabsTrigger>
          <TabsTrigger value="events">Events</TabsTrigger>
          <TabsTrigger value="smu">SMU</TabsTrigger>
        </TabsList>
        <div>
          <TabsContent value="general">
            <SiteCard site={site} />
          </TabsContent>
          <TabsContent value="maintenance">
            <div className="flex flex-col gap-4">
              <SystemsConfig site={site} />
              <StatusConfig site={site} />
              <TypeConfig site={site} />
            </div>
          </TabsContent>
          <TabsContent value="events">
            <div className="flex flex-col gap-4">
              <SiteTypesConfig site={site} />
              <SiteStatusConfig site={site} />
            </div>
          </TabsContent>
          <TabsContent value="smu">
            <SmuConfiguration site={site} />
          </TabsContent>
        </div>
      </Tabs>
    </div>
  )
}

export default SiteContainer;