import { useEffect, useState } from "react";
import { useSiteSelector } from "@/components/SiteProvider";
import { NewMaintenanceLog } from "./table/columns";
import { AnalyticsObj } from "../overview/Overview";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { calculateMTTR, calculatePlannedVsUnplannedMaintenance, calculateTDT } from "../reporting/metrics/metrics";
import OverviewBarChart from "../overview/BarChart";
import moment from "moment";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { fetchMaintenanceLogs } from "./queries";
import { ChartTest } from "./ChartTest";

interface WeeklyMaintenanceData {
  week: string;
  plannedCount: number;
  unplannedCount: number;
}

const processWeeklyMaintenanceData = (maintenanceLogs: NewMaintenanceLog[]): WeeklyMaintenanceData[] => {
  const weeklyData: { [key: string]: WeeklyMaintenanceData } = {};
  const plannedDownTypes = ["Scheduled", "Preventative", "Servicing", "Opportune"];

  maintenanceLogs.forEach(log => {
    if (!log.date_down) return;

    const weekStart = moment(log.date_down).startOf('week').format('YYYY-MM-DD');
    if (!weeklyData[weekStart]) {
      weeklyData[weekStart] = { week: weekStart, plannedCount: 0, unplannedCount: 0 };
    }

    if (plannedDownTypes.includes(log.type?.name)) {
      weeklyData[weekStart].plannedCount += 1;
    } else {
      weeklyData[weekStart].unplannedCount += 1;
    }
  });

  return Object.values(weeklyData).sort((a, b) => a.week.localeCompare(b.week));
};

const MaintenanceAnalytics = () => {
  const { selectedSite } = useSiteSelector();
  const [maintenanceLogs, setMaintenanceLogs] = useState<NewMaintenanceLog[]>()
  const [mTypeCount, setMTypeCount] = useState<AnalyticsObj[]>([])
  const [mSystemCount, setMSystemCount] = useState<AnalyticsObj[]>([])
  const [mStatusCount, setMStatusCount] = useState<AnalyticsObj[]>([])
  const [weeklyMaintenanceData, setWeeklyMaintenanceData] = useState<WeeklyMaintenanceData[]>([]);

  useEffect(() => {
    if (!selectedSite) return;

    const getMaintenanceLogs = async () => {
      const { data, error } = await fetchMaintenanceLogs(selectedSite.id);

      if (data) {
        setMaintenanceLogs(data);
      }

      if (error) {
        alert(error.message)
      }
    }

    getMaintenanceLogs();
  }, [selectedSite]);

  useEffect(() => {
    if (!maintenanceLogs) return;

    const typeCounts: { [type: string]: number; } = {};
    const systemCounts: { [system: string]: number; } = {};
    const statusCounts: { [status: string]: number; } = {};

    maintenanceLogs.forEach(item => {
      const type = item.type?.name;
      const system = item.system?.name;
      const status = item.status?.name;

      if (type) {
        typeCounts[type] = (typeCounts[type] || 0) + 1;
      }
      if (system) {
        systemCounts[system] = (systemCounts[system] || 0) + 1;
      }
      if (status) {
        // if (status === "Complete") return;
        statusCounts[status] = (statusCounts[status] || 0) + 1;
      }
    });
    const typeCountArr = Object.keys(typeCounts).map(key => {
      return {
        name: key,
        value: typeCounts[key]
      }
    });
    const systemCountsArr = Object.keys(systemCounts).map(key => {
      return {
        name: key,
        value: systemCounts[key]
      }
    });
    const statusCountsArr = Object.keys(statusCounts).map(key => {
      return {
        name: key,
        value: statusCounts[key]
      }
    });
    setMTypeCount(typeCountArr)
    setMSystemCount(systemCountsArr)
    setMStatusCount(statusCountsArr)

    setWeeklyMaintenanceData(processWeeklyMaintenanceData(maintenanceLogs))
  }, [maintenanceLogs])

  return (
    <div>
      {!maintenanceLogs &&
        <div className="w-full flex justify-center">
          <LoadingSpinner />
        </div>
      }

      {maintenanceLogs &&
        <>
          <div className="w-full flex gap-6">
            <Card className="flex-1">
              <CardHeader>
                <CardTitle>Maintenance Events</CardTitle>
              </CardHeader>
              <CardContent>
                {maintenanceLogs.length}
              </CardContent>
            </Card>

            <Card className="flex-1">
              <CardHeader>
                <CardTitle>Maintenance Downtime</CardTitle>
              </CardHeader>
              <CardContent>
                {calculateTDT(maintenanceLogs).toFixed(0) + " hrs"}
              </CardContent>
            </Card>

            <Card className="flex-1">
              <CardHeader>
                <CardTitle>Mean Time To Repair (MTTR)</CardTitle>
              </CardHeader>
              <CardContent>
                {calculateMTTR(maintenanceLogs).toFixed(1) + " hrs"}
              </CardContent>
            </Card>

            <Card className="flex-1">
              <CardHeader>
                <CardTitle>Planned vs Unplanned Maintenance</CardTitle>
              </CardHeader>
              <CardContent>
                {calculatePlannedVsUnplannedMaintenance(maintenanceLogs).toFixed(1) + " %"}
              </CardContent>
            </Card>
          </div>

          <div className="grid grid-cols-3 gap-4 pt-6">
            <Card>
              <CardHeader>
                <CardTitle>Maintenance Downtime Types</CardTitle>
              </CardHeader>
              <CardContent>
                <OverviewBarChart data={mTypeCount} />
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Maintenance Statuses</CardTitle>
              </CardHeader>
              <CardContent>
                <OverviewBarChart data={mStatusCount} />
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Maintenance Systems</CardTitle>
              </CardHeader>
              <CardContent>
                <OverviewBarChart data={mSystemCount} />
              </CardContent>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle>Maintenance Downtime Types</CardTitle>
              </CardHeader>
              <CardContent>
                <ChartTest chartData={mTypeCount} />
              </CardContent>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle>Maintenance Statuses</CardTitle>
              </CardHeader>
              <CardContent>
                <ChartTest chartData={mStatusCount} />
              </CardContent>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle>Maintenance Systems</CardTitle>
              </CardHeader>
              <CardContent>
                <ChartTest chartData={mSystemCount} />
              </CardContent>
            </Card>
          </div>

          <div className="pt-6">
            <Card>
              <CardHeader>
                <CardTitle>Weekly Maintenance Trends</CardTitle>
              </CardHeader>
              <CardContent>
                <ResponsiveContainer width="100%" height={400}>
                  <LineChart data={weeklyMaintenanceData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="week" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="plannedCount" stroke="#8884d8" name="Planned Maintenance Events" />
                    <Line type="monotone" dataKey="unplannedCount" stroke="#82ca9d" name="Unplanned Maintenance Events" />
                  </LineChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>
          </div>
        </>
      }
    </div >
  )
}

export default MaintenanceAnalytics;