import { Button } from "@/components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { zodResolver } from "@hookform/resolvers/zod"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"
import { ExclamationTriangleIcon } from "@radix-ui/react-icons"
import { Navigate } from "react-router-dom"
import { useAuth } from "@/components/AuthProvider"

export const formSchema = z.object({
  username: z.string().min(1, { message: "Username is required" }).email("Please enter a valid email."),
  password: z.string(),
});

export function LoginForm() {
  const { user, signIn } = useAuth();
  const [isloading, setIsLoading] = useState<boolean>(false);
  const [loginErrors, setLoginErrors] = useState<string | undefined>(undefined);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema)
  })

  if (user) {
    return <Navigate to='/' replace />;
  }

  const login = async (values: z.infer<typeof formSchema>) => {
    if (!signIn) return;
    setIsLoading(true);

    const { error } = await signIn(
      values.username,
      values.password
    )

    if (error) {
      setLoginErrors(error.message);
    }

    setIsLoading(false);
  }

  return (
    <div className="w-screen flex flex-col items-center justify-center pt-64">
      <Card className="mx-auto max-w-sm">
        <CardHeader>
          <CardTitle>Login</CardTitle>
          <CardDescription>
            Enter your email below to login to your account
          </CardDescription>
        </CardHeader>
        <CardContent>
          {loginErrors &&
            <Alert variant="destructive">
              <ExclamationTriangleIcon className="h-4 w-4" />
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>{loginErrors}</AlertDescription>
            </Alert>
          }
          <Form {...form}>
            <form className="space-y-2">
              <FormField
                control={form.control}
                name="username"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Username</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Enter your email"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Password</FormLabel>
                    <FormControl>
                      <Input
                        type="password"
                        placeholder="Enter your password"
                        autoComplete="new-password"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex">
                <Button type="submit" className="w-full" onClick={form.handleSubmit(login)} disabled={!form.formState.isDirty || !form.formState.isValid}>{isloading ? "Logging In..." : "Login"}</Button>
              </div>
            </form >
          </Form >
        </CardContent>
      </Card>
    </div>
  )
}
